<section>
    <div class="row no-gutters">
        <div class="col-2 p-0 web" style="background: #0e2e7b;">
            <app-sidenav></app-sidenav>
        </div>
        <div class="col-md-10 col-sm-12 px-3">
          <div class="row no-gutters py-3">
            <div class="col-md-12" *ngIf="mainservice.checkType == 1">
                <div class="card border-0">
                    <div class="col-sm-12">
                    <div class="row align-items-center px-3">
                      <div class="col-md-6">  
                        <h2 class="card-title mb-0">All Pages</h2>
                      </div>
                      <div class="col-md-6 text-right mt-1">                
                        <button class="btn-add btn" (click)="addPage()">Add 
                          <span class="material-icons">
                              add
                          </span>
                      </button>
                      </div>
                    </div>
                  </div> 
                  <!-- <hr> -->
                <div class="card-body content-height">
                  <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col"><p><b>No</b></p></th>
                        <th scope="col"><p><b>Title</b></p></th>
                        <th scope="col"><p><b>Name</b></p></th>
                        <!-- <th scope="col"><p><b>Sort Description</b></p></th> -->
                        <th scope="col"><p><b>Banner</b></p></th>
                        <th scope="col"><p><b>Status</b></p></th>
                        <th scope="col"><p><b>Action</b></p></th>                
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor=" let Page of allPages; let i=index;">
                        <td>{{(4*(p-1))+(i+1)}}</td>            
                        <td>{{Page.page_title}}</td>
                        <td>{{Page.page_name}}</td>
                        <!-- <td [innerHtml]="Page.sort_description"></td>             -->
                        <td class="text-left"><img [src]="Page.banner_img" alt="banner_img"></td>
                        <ng-container *ngIf="Page.status == 1">
                          <td><label class="switch">
                            <input type="checkbox" checked (click)="status($event.target,Page.page_id)">
                            <span class="slider round"></span>
                            </label>
                          </td>
                        </ng-container>
                        <ng-container *ngIf="Page.status == 0">
                          <td>
                            <label class="switch">
                              <input type="checkbox" (click)="status($event.target,Page.page_id)">
                              <span class="slider round"></span>
                            </label>
                          </td>
                        </ng-container>
                        <td class="td-actions">
                                <form >
                                <!-- <a class="btn btn-sm btn-danger"><i class="fa fa-minus-circle"></i></a>&nbsp; -->
                                <a (click)="editPage(Page.url)" class="btn btn-sm">
                                  <i class="fa fa-edit text-white"></i>
                                </a>&nbsp;
                                <!-- <a class="btn btn-sm btn-warning"><i class="fa fa-eye"></i>
                                </a>&nbsp; -->
                                </form>
                        </td>
                        </tr>            
                    </tbody>
                  </table> 
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="mainservice.checkType == 2">
              <app-add-page></app-add-page>
            </div>
            <div class="col-md-12" *ngIf="mainservice.checkType == 3">
              <app-edit-page></app-edit-page>
            </div>
            <!-- <div class="col-12 text-center" *ngIf="allPages.length>0">
              <pagination-controls class="text-center" (pageChange)="p = $event" id="Pages"></pagination-controls>
            </div> -->
        </div>
        </div>
    </div>
</section>