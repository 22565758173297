<div class="container-xxl bg-white p-0">
    <!-- Spinner Start -->
    <!-- <div id="spinner"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div> -->
    <!-- Spinner End -->


    <!-- Navbar & Hero Start -->
    <div class="container-xxl position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
            <a href="" class="navbar-brand p-0">
                <h1 class="m-0 logo_css">
                    अखिल भारतीय अग्रवाल महासभा</h1>
                <!-- <img src="img/logo.png" alt="Logo"> -->
            </a>
            <button class="navbar-toggler" type="button" (click)="openNav()">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
                <div class="navbar-nav ms-auto py-0">
                    <a routerLink="/" class="nav-item nav-link active">होम</a>
                    <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                    </a>
                    <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                    <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                    <!-- <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div class="dropdown-menu m-0">
                            <a href="team.html" class="dropdown-item">Our Team</a>
                            <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                            <a href="404.html" class="dropdown-item">404 Page</a>
                        </div>
                    </div> -->
                    <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                    <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                    <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
                    <!-- <a routerLink="/contact-us" class="nav-item nav-link">संपर्क करें</a> -->

                </div>
            </div>

            <!-- <div class="container mob"> 
                <div class="col-12">
                    <div id="mySidepanel" class="sidepanel">
                        <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">×</a>
                        <a (click)="closeNav()" routerLink="/">HOME</a>
                        <a (click)="closeNav()" routerLink="/about-us">ABOUT US</a>
                        <a (click)="closeNav()" class="" routerLink="/about-us">BUSSINESS</a>
                        <a (click)="closeNav()" routerLink="/">ACCOUNTANCY SERVICES</a>
                        <a (click)="closeNav()" routerLink="/">CITIZENSHIP PROGRAMS</a>
                        <a (click)="closeNav()" routerLink="/blogs">BLOGS</a>
                        <a (click)="closeNav()" routerLink="/contact-us">CONTACT US</a>
                        <a (click)="closeNav()" *ngIf="user!=''" routerLink="/contact-us">Admin</a>
                    </div>
                </div>
            </div> -->
        </nav>
        <!-- <app-auth-header></app-auth-header> -->
        <div class="container-xxl py-5 bg-primary hero-header mb-5">
            <div class="container my-5 py-5 px-lg-5">
                <div class="row g-5 py-5">
                    <div class="col-lg-6 text-center text-lg-start">
                        <!-- <h1 class="text-white mb-4 animated zoomIn">All in one SEO tool need to grow your business
                            rapidly All in one SEO tool need to grow your business
                            rapidlyAll in one SEO tool need to grow your business
                            rapidlyAll in one SEO tool need to grow your business
                            rapidlyAll in one SEO tool need to grow your business
                            rapidly</h1> -->
                        <p class="text-white pb-3 animated zoomIn subh">इस वेबसाइट का उद्देश्य समस्त अग्र बंधुओं को
                            एकजुट
                            करना है अग्रबंधु पूरे भारतवर्ष में तथा विदेशों में भी फैले हुए हैं जो कि इस वेबसाइट के
                            माध्यम से एक दूसरे के संपर्क में रह सकते हैं समाज में एक दूसरे की मदद कर सकते हैं तथा
                            आवश्यकता होने पर समाज से मदद ले भी सकते हैं
                            अग्रवाल समाज व्यापार के क्षेत्र में सदैव अग्रणी रहा है देश विदेश में करीब 10 करोड़ अग्रबंधु
                            हैं हमारा व्यापार देश विदेश में कोने कोने में फैला हुआ है इस वेबसाइट के माध्यम से समस्त अग्र
                            बंधु एक दूसरे को जानने समझने के साथ-साथ व्यापार में भी एक दूसरे की मदद ले व दे सकते हैं
                        </p>
                        <a routerLink="/register-form"
                            class="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft">सदस्यता
                            फार्म</a>
                        <!-- <a href=""
                            class="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInLeft">संपर्क
                            करें</a> -->
                    </div>
                    <div class="col-lg-6 text-center text-lg-start">
                        <img class="img-fluid" src="assets/images/img/hero.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Navbar & Hero End -->


    <!-- Full Screen Search Start -->
    <div class="modal fade" id="searchModal" tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content" style="background: rgba(29, 29, 39, 0.7);">
                <div class="modal-header border-0">
                    <button type="button" class="btn bg-white btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <div class="input-group" style="max-width: 600px;">
                        <input type="text" class="form-control bg-transparent border-light p-3"
                            placeholder="Type search keyword">
                        <button class="btn btn-light px-4"><i class="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Full Screen Search End -->


    <!-- About Start -->
    <div class="container-xxl py-5">
        <div class="container px-lg-5">
            <div class="row g-5">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="section-title position-relative mb-4 pb-2">
                        <!-- <h6 class="position-relative text-primary ps-4">About Us</h6> -->
                        <h2 class="mt-2">महाराजा श्री अग्रसेन जी का जीवन परिचय
                        </h2>
                    </div>
                    <p class="mb-4">अग्रकुल संस्थापक महाराजा श्री अग्रसेन जी का अवतरण करीब 5100 वर्ष पूर्व हुआ था
                        महाराजा अग्रसेन भारतीय अर्थव्यवस्था और व समाजवाद के प्रणेता थे
                        प्रत्येक अग्रवाल के घर में अग्रसेन महाराज का चित्र आवश्यक रूप से होना चाहिए
                    </p>
                    <!-- <div class="row g-3">
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Award Winning</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                        </div>
                    </div> -->
                    <div class="d-flex align-items-center mt-4">
                        <a class="btn btn-primary rounded-pill px-4 me-3" routerLink="/about-maharaj">आगे पढ़े</a>
                        <!-- <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-primary btn-square me-3" href=""><i class="fab fa-instagram"></i></a>
                        <a class="btn btn-outline-primary btn-square" href=""><i class="fab fa-linkedin-in"></i></a> -->
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row g-3">
                        <div class="col-sm-6">
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गर्ग &nbsp;(Garg or Gargeya)
                            </h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गोयल (Goyal, Goel or Goenka)
                            </h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>जिंदल (Jindal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बिंदल (Bindal or Vindal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कंदल &nbsp;(Kandal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बंसल (Bansal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>जिंजल (Jinjal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बिंछल (Binchhal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कांसल (Kansal)</h6>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>भेदल &nbsp;(Bhedal / Bhandal)
                            </h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>मंगल (Mangal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>मित्तल (Mittal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>सिंगल (Singal, Singhal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>एरण (Airan/Aeron)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>नांगल (Nangal)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कुंच्छल (Kuchhal / Kuchchal)
                            </h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>धारण (Dharan or Deran)</h6>
                            <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गोइन (Goin, Goyan, Gangal)
                            </h6>
                        </div>
                    </div>
                    <!-- <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="assets/images/img/about.jpg"> -->
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->


    <!-- Newsletter Start -->
    <div class="container-xxl bg-primary newsletter my-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container px-lg-5">
            <div class="row align-items-center" style="height: 250px;">
                <div class="col-12 col-md-6">
                    <h3 class="text-white">सहयोग करें / दान करें</h3>
                    <small class="text-white">आपके द्वारा दान की गई राशि का पूर्ण पारदर्शिता के साथ सामाजिक उत्थान के
                        लिए उपयोग होता है जिसका विवरण किसी भी सदस्य के द्वारा वार्षिक बैठक में लिया जा सकता है.</small>
                    <div class="position-relative w-100 mt-3">
                        <!-- <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text"
                            placeholder="Enter Your Email" style="height: 48px;"> -->
                        <!-- <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                class="fa fa-paper-plane text-primary fs-4"></i></button> -->
                        <div class="d-flex align-items-center mt-4">
                            <button type="button"
                                class="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft"> दान
                                करें</button>
                            <!-- <form>
                                <script src="https://checkout.razorpay.com/v1/payment-button.js"
                                    data-payment_button_id="pl_IYCZeiKqwYpDR0" async> </script>
                            </form> -->
                            <!-- <form>
                                <script src="https://checkout.razorpay.com/v1/payment-button.js"
                                    data-payment_button_id="pl_IQzNHSKcOYWArD" async> </script>
                            </form> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 text-center mb-n5 d-none d-md-block">
                    <img class="img-fluid mt-5" style="height: 250px;" src="assets/images/img/logo.png">
                </div>
            </div>
        </div>
    </div>
    <!-- Newsletter End -->


    <!-- Service Start -->
    <div class="container-xxl py-5">
        <div class="container px-lg-5">
            <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                <!-- <h6 class="position-relative d-inline text-primary ps-4">Our Services</h6> -->
                <h2 class="mt-2">महासभा द्वारा दी जा रही सेवाएं</h2>
            </div>
            <div class="row g-4">
                <div class="col-lg-4 col-md-6 wow zoomIn" style="cursor: pointer;" data-wow-delay="0.1s"
                    routerLink="/register-book">
                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div class="service-icon flex-shrink-0">
                            <i class="fa fa-book fa-2x"></i>
                        </div>
                        <h5 class="mb-3">किताब संग्रह (बुक बैंक)</h5>
                        <p>इस योजना के तहत महासभा द्वारा किताबें दान में लेकर उनको जरूरतमंद छात्रों को उपलब्ध करवाना है.
                        </p>
                        <!-- <a class="btn px-3 mt-auto mx-auto" href="">Read More</a> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div class="service-icon flex-shrink-0">
                            <i class="fa fa-compass fa-2x"></i>
                        </div>
                        <h5 class="mb-3">शादी विवाह</h5>
                        <p>इस योजना के तहत महासभा द्वारा योग्य युवक व युवतियों के बायोडाटा वेबसाइट पर डाले जाते हैं एवं
                            बायोडाटा को संबंधित जिले के प्रभारी के द्वारा पूर्ण जांच करने के पश्चात ही वेबसाइट पर डाला
                            जाता है
                        </p>
                        <!-- <a class="btn px-3 mt-auto mx-auto" href="">Read More</a> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div class="service-icon flex-shrink-0">
                            <!-- <i class="fas fa-graduate fa-2x"></i> -->
                            <i class="fa fa-graduation-cap fa-2x"></i>
                        </div>
                        <h5 class="mb-3">नौकरी पोर्टल
                        </h5>
                        <p>इस योजना के तहत जो अग्रबंधु नौकरी पाना चाहते हैं वह अपना पूरा बायोडाटा इसमें डाल सकते हैं तथा
                            जो अग्रवाल भाई व्यवसाय करते हैं एवं अपने अग्र बंधुओं को नौकरी का अवसर देना चाहते हैं वह अपने
                            लिए उपयुक्त बायोडाटा देख कर उनसे संपर्क कर उनको नौकरी दे सकते हैं .</p>
                        <!-- <a class="btn px-3 mt-auto mx-auto" href="">Read More</a> -->
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div class="service-icon flex-shrink-0">
                            <i class="fa fa-home fa-2x"></i>
                        </div>
                        <h5 class="mb-3">Email Marketing</h5>
                        <p>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet
                            lorem.</p>
                        <a class="btn px-3 mt-auto mx-auto" href="">Read More</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div class="service-icon flex-shrink-0">
                            <i class="fa fa-home fa-2x"></i>
                        </div>
                        <h5 class="mb-3">PPC Advertising</h5>
                        <p>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet
                            lorem.</p>
                        <a class="btn px-3 mt-auto mx-auto" href="">Read More</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div class="service-icon flex-shrink-0">
                            <i class="fa fa-home fa-2x"></i>
                        </div>
                        <h5 class="mb-3">App Development</h5>
                        <p>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet
                            lorem.</p>
                        <a class="btn px-3 mt-auto mx-auto" href="">Read More</a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- Service End -->


    <!-- Portfolio Start -->
    <!-- <div class="container-xxl py-5">
        <div class="container px-lg-5">
            <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                <h6 class="position-relative d-inline text-primary ps-4">Our Projects</h6>
                <h2 class="mt-2">Recently Launched Projects</h2>
            </div>
            <div class="row mt-n2 wow fadeInUp" data-wow-delay="0.1s">
                <div class="col-12 text-center">
                    <ul class="list-inline mb-5" id="portfolio-flters">
                        <li class="btn px-3 pe-4 active" data-filter="*">All</li>
                        <li class="btn px-3 pe-4" data-filter=".first">Design</li>
                        <li class="btn px-3 pe-4" data-filter=".second">Development</li>
                    </ul>
                </div>
            </div>
            <div class="row g-4 portfolio-container">
                <div class="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.1s">
                    <div class="position-relative rounded overflow-hidden">
                        <img class="img-fluid w-100" src="assets/images/img/portfolio-1.jpg" alt="">
                        <div class="portfolio-overlay">
                            <a class="btn btn-light" href="assets/images/img/portfolio-1.jpg"
                                data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                            <div class="mt-auto">
                                <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.3s">
                    <div class="position-relative rounded overflow-hidden">
                        <img class="img-fluid w-100" src="assets/images/img/portfolio-2.jpg" alt="">
                        <div class="portfolio-overlay">
                            <a class="btn btn-light" href="assets/images/img/portfolio-2.jpg"
                                data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                            <div class="mt-auto">
                                <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.6s">
                    <div class="position-relative rounded overflow-hidden">
                        <img class="img-fluid w-100" src="assets/images/img/portfolio-3.jpg" alt="">
                        <div class="portfolio-overlay">
                            <a class="btn btn-light" href="assets/images/img/portfolio-3.jpg"
                                data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                            <div class="mt-auto">
                                <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.1s">
                    <div class="position-relative rounded overflow-hidden">
                        <img class="img-fluid w-100" src="assets/images/img/portfolio-4.jpg" alt="">
                        <div class="portfolio-overlay">
                            <a class="btn btn-light" href="assets/images/img/portfolio-4.jpg"
                                data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                            <div class="mt-auto">
                                <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item first wow zoomIn" data-wow-delay="0.3s">
                    <div class="position-relative rounded overflow-hidden">
                        <img class="img-fluid w-100" src="assets/images/img/portfolio-5.jpg" alt="">
                        <div class="portfolio-overlay">
                            <a class="btn btn-light" href="assets/images/img/portfolio-5.jpg"
                                data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                            <div class="mt-auto">
                                <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow zoomIn" data-wow-delay="0.6s">
                    <div class="position-relative rounded overflow-hidden">
                        <img class="img-fluid w-100" src="assets/images/img/portfolio-6.jpg" alt="">
                        <div class="portfolio-overlay">
                            <a class="btn btn-light" href="assets/images/img/portfolio-6.jpg"
                                data-lightbox="portfolio"><i class="fa fa-plus fa-2x text-primary"></i></a>
                            <div class="mt-auto">
                                <small class="text-white"><i class="fa fa-folder me-2"></i>Web Design</small>
                                <a class="h5 d-block text-white mt-1 mb-0" href="">Project Name</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Portfolio End -->


    <!-- Testimonial Start -->
    <!-- <div class="container-xxl bg-primary testimonial py-5 my-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5 px-lg-5">
            <div class="owl-carousel testimonial-carousel">
                <div class="testimonial-item bg-transparent border rounded text-white p-4">
                    <i class="fa fa-quote-left fa-2x mb-3"></i>
                    <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam
                    </p>
                    <div class="d-flex align-items-center">
                        <img class="img-fluid flex-shrink-0 rounded-circle" src="assets/images/img/testimonial-1.jpg"
                            style="width: 50px; height: 50px;">
                        <div class="ps-3">
                            <h6 class="text-white mb-1">Client Name</h6>
                            <small>Profession</small>
                        </div>
                    </div>
                </div>
                <div class="testimonial-item bg-transparent border rounded text-white p-4">
                    <i class="fa fa-quote-left fa-2x mb-3"></i>
                    <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam
                    </p>
                    <div class="d-flex align-items-center">
                        <img class="img-fluid flex-shrink-0 rounded-circle" src="assets/images/img/testimonial-2.jpg"
                            style="width: 50px; height: 50px;">
                        <div class="ps-3">
                            <h6 class="text-white mb-1">Client Name</h6>
                            <small>Profession</small>
                        </div>
                    </div>
                </div>
                <div class="testimonial-item bg-transparent border rounded text-white p-4">
                    <i class="fa fa-quote-left fa-2x mb-3"></i>
                    <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam
                    </p>
                    <div class="d-flex align-items-center">
                        <img class="img-fluid flex-shrink-0 rounded-circle" src="assets/images/img/testimonial-3.jpg"
                            style="width: 50px; height: 50px;">
                        <div class="ps-3">
                            <h6 class="text-white mb-1">Client Name</h6>
                            <small>Profession</small>
                        </div>
                    </div>
                </div>
                <div class="testimonial-item bg-transparent border rounded text-white p-4">
                    <i class="fa fa-quote-left fa-2x mb-3"></i>
                    <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam
                    </p>
                    <div class="d-flex align-items-center">
                        <img class="img-fluid flex-shrink-0 rounded-circle" src="assets/images/img/testimonial-4.jpg"
                            style="width: 50px; height: 50px;">
                        <div class="ps-3">
                            <h6 class="text-white mb-1">Client Name</h6>
                            <small>Profession</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Testimonial End -->


    <!-- Team Start -->
    <!-- <div class="container-xxl py-5">
        <div class="container px-lg-5">
            <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                <h6 class="position-relative d-inline text-primary ps-4">Our Team</h6>
                <h2 class="mt-2">Meet Our Team Members</h2>
            </div>
            <div class="row g-4">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item">
                        <div class="d-flex">
                            <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                                style="width: 75px;">
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-twitter"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-instagram"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-linkedin-in"></i></a>
                            </div>
                            <img class="img-fluid rounded w-100" src="assets/images/img/team-1.jpg" alt="">
                        </div>
                        <div class="px-4 py-3">
                            <h5 class="fw-bold m-0">Jhon Doe</h5>
                            <small>CEO</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="team-item">
                        <div class="d-flex">
                            <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                                style="width: 75px;">
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-twitter"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-instagram"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-linkedin-in"></i></a>
                            </div>
                            <img class="img-fluid rounded w-100" src="assets/images/img/team-2.jpg" alt="">
                        </div>
                        <div class="px-4 py-3">
                            <h5 class="fw-bold m-0">Emma William</h5>
                            <small>Manager</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                    <div class="team-item">
                        <div class="d-flex">
                            <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                                style="width: 75px;">
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-twitter"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-instagram"></i></a>
                                <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                        class="fab fa-linkedin-in"></i></a>
                            </div>
                            <img class="img-fluid rounded w-100" src="assets/images/img/team-3.jpg" alt="">
                        </div>
                        <div class="px-4 py-3">
                            <h5 class="fw-bold m-0">Noah Michael</h5>
                            <small>Designer</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Team End -->

    <app-auth-footer></app-auth-footer>


    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top pt-2"><i class="bi bi-arrow-up"></i></a>
</div>