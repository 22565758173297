import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BlogComponent } from './blog/blog.component';
import { PagesComponent } from './pages/pages.component';
import { AddblogComponent } from './addblog/addblog.component';
import { EditblogComponent } from './editblog/editblog.component';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { EditPageComponent } from './edit-page/edit-page.component';
import { AddPageComponent } from './add-page/add-page.component';
import { ContactsComponent } from './contacts/contacts.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

const dashRoute : Routes =[
  // {path: '', component:MaindashComponent,children:[

  // ]}
 // {path: '', component:MaindashComponent},
  {path : 'blog' , component:BlogComponent},
  {path : 'pages' , component:PagesComponent},
  {path: 'subscribers' , component:SubscribersComponent},
  {path : 'contacts' , component:ContactsComponent}
];

@NgModule({
  declarations: [SidenavComponent,BlogComponent, PagesComponent, AddblogComponent, EditblogComponent, SubscribersComponent, EditPageComponent, AddPageComponent, ContactsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    RouterModule.forChild(dashRoute)
  ],
  exports: [RouterModule]
})
export class DashboardModule { }
