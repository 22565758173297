import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class MainService {
  checkType = 1;
  constructor(public http: HttpClient, public router: Router) { }
  apiUrl = environment.apiUrl;
  apiToken = environment.apiToken;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'ApiToken': this.apiToken,
    })
  };
  AdminLog(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}admin_login`, data, this.httpOptions);
  }
  allblogs(data: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}blogs?total=` + data, this.httpOptions);
  }

  addBlog(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}add_blog`, data, this.httpOptions);
  }
  // blogDetail(data:any):Observable<any>{
  //   return this.http.get<any>(`${this.apiUrl}blog_detail?blog_id=`+ data,this.httpOptions);
  // }
  blogDetail(data: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}blog_detail?id=` + data, this.httpOptions);
  }
  editBlog(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}edit_blog`, data, this.httpOptions);
  }
  statusChange(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}status-change`, data, this.httpOptions);
  }
  ContactForms(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}saveContact`, data, this.httpOptions);
  }
  DemoForms(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}demo`, data, this.httpOptions);
  }
  stuAmbssadorForms(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}add_stu_abssdr`, data, this.httpOptions);
  }
  SubscribeForm(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}subscription`, data, this.httpOptions);
  }
  TotalSubscriber(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}subscriber_list`, this.httpOptions);
  }
  TotalContact(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}get_contact`, this.httpOptions);
  }


  totalPages(data: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}allPages?total=` + data, this.httpOptions);
  }
  addPage(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}add_page`, data, this.httpOptions);
  }
  pagestatusChange(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}page-status`, data, this.httpOptions);
  }
  pageDetail(data: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}page_detail?page_id=` + data, this.httpOptions);
  }
  editPage(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}edit_page`, data, this.httpOptions);
  }
  deletePage(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}delete_page`, data, this.httpOptions);
  }


  customPages(type: any, sub: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}allPages?type=${type}&sub_type=${sub}`, this.httpOptions);
  }
  pageDetails(type: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}page_detail?page_id=${type}`, this.httpOptions);
  }

  registerForm(data: any): Observable<any> {
    // return this.httpPostUploadimage(`${this.apiUrl}createPDF`, data);
    return this.httpPostUploadimage(`${this.apiUrl}payonline`, data);
  }

  matrimonyForm(data: any): Observable<any> {
    // return this.httpPostUploadimage(`${this.apiUrl}createPDF`, data);
    return this.httpPostUploadimage(`${this.apiUrl}userMatrimony`, data);
  }

  bookForm(data: any): Observable<any> {
    // return this.httpPostUploadimage(`${this.apiUrl}createPDF`, data);
    return this.httpPostUploadimage(`${this.apiUrl}bookonline`, data);
  }

  createPDF(data: any): Observable<any> {
    return this.httpPostUploadimage(`${this.apiUrl}createPDF`, data);
  }

  getCountry(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}getCountry`, this.httpOptions);
  }

  getState(data: any): Observable<any> {
    return this.httpPostUploadimage(`${this.apiUrl}getState`, data);
  }

  getCity(data: any): Observable<any> {
    return this.httpPostUploadimage(`${this.apiUrl}getCity`, data);
  }

  verifyMember(data: any): Observable<any> {
    return this.httpPostUploadimage(`${this.apiUrl}verifyMember`, data);
  }


  httpPostUploadimage(endpoint: string, param: any) {
    let user: any;
    if (sessionStorage.getItem('user') != null) {
      user = JSON.parse(sessionStorage.getItem('user')!);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': (user) ? 'Bearer ' + user.authToken : '',
      })
    };
    return this.http.post<any>(endpoint, param, httpOptions);
  }

}

