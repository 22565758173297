import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  allBlogs: any =[];
  statusData: any;
  p:number = 1;
  constructor(public router:Router,public mainservice:MainService,private notificationService:NotifyService) { }
  addBlog(){
    this.mainservice.checkType = 2;
  }
  editBlog(val:any){
    var blog_id = val;
    console.log(blog_id);
    sessionStorage.setItem('blog_id',blog_id);
    this.mainservice.checkType = 3;
  }
  status(status:any,id:any){
    console.log(status,id);
    if(status.checked){
      this.statusData = {
        'id' : id,
        'status' : 1
      }
    }else{
      this.statusData = {
        'id' : id,
        'status' : 0
      }
    }
    if(confirm("Are you sure to change status")) {
      this.mainservice.statusChange(this.statusData).subscribe((res:any)=>{
        if(res['success']){
          this.notificationService.showSuccess("Status Changed Successfully", "Success !!");
          //alert('success');
        }else{
          this.notificationService.showError("Something went wrong", "Failure !!");
        }
      });
    }

  }
  blog(){
    this.mainservice.allblogs('total').subscribe((blog:any)=>{
      this.allBlogs =blog['blogs'];
    //  console.log("blogs",this.allBlogs);
    });
  }
  ngOnInit() {
    sessionStorage.setItem('blog_id','');
    this.blog();
  }

}
