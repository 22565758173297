<!-- Navbar & Hero Start -->
<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>

    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn">जीवन परिचय</h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Navbar & Hero End -->


<div class="container-xxl">
    <div class="container px-lg-1">
        <div class="row g-5">
            <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <div class="section-title position-relative mb-4 pb-2">
                    <h2 class="mt-2">महाराजा श्री अग्रसेन जी का जीवन परिचय
                    </h2>
                </div>
                <p class="mb-4">
                    अग्रकुल संस्थापक महाराजा श्री अग्रसेन जी का जीवन चरित्र
                    आज से 5100 वर्ष पूर्व भारत के ऐसे युगपुरुष का अवतरण हुआ जिसने भारतीय अर्थव्यवस्था को व राज व्यवस्था
                    को एक नया रूप दिया महाराजा श्री अग्रसेन के पिता का नाम श्री बल्लभ सेन था एवं वह प्रतापपुर के शासक थे
                    उनके 18 पुत्र थे एवं एक पुत्री थी पुत्री का विवाह काशीराज नरेश महेश से किया था तथा यह मान्यता भी है
                    कि अग्रसेन जी की पुत्री ईश्वरी और राजा महेश के संतान आज महेश्वरी कहलाते हैं
                    महाराजा अग्रसेन को समाजवाद पूंजीवाद के भारतीय स्वरूप के प्रणेता कहा गया है एक ईट व ₹1 की प्रथा
                    उन्होंने ही चालू की थी एक बार महाराजा अग्रसेन अपने राज्य का निरीक्षण करने के लिए गए वहां जेल में
                    उनके बचपन का एक साथी मिला उन्होंने उसके जेल में आने का कारण पूछा तो उस व्यक्ति ने कहा कि मैं और मेरा
                    परिवार भूख से पीड़ित था कई दिनों से कुछ नहीं खाया इस कारण खाद्य सामग्री को चुराया क्योंकि मैं बच्चों
                    को भूखे मरते हुए नहीं देख सकता था उसी अपराध के कारण में जेल में हूं महाराजा अग्रसेन ने विचार किया कि
                    मैं कैसा राजा हूं कि मेरे राज्य में लोग भूखे मर रहे हैं और मुझे पता ही नहीं उनका यह मंथन सारी रात
                    चलता रहा नींद नहीं आई कि यदि राज्य का कोई व्यक्ति भूख के कारण कोई अपराध करता है तो अपराध उस अपराधी
                    का नहीं बल्कि राजा का हुआ सजा उस व्यक्ति को नहीं बल्कि स्वयं राजा को मिलनी चाहिए इस प्रकार के महान
                    विचारक थे उन्होंने विचार अपने राज दरबार में रखा राज दरबारीयो ने कहा कि यदि इस प्रकार के अपराधियों को
                    छोड़ दिया गया अपराध की मात्रा बहुत बढ़ जाएगी अपराधियों के मन से सजा का डर निकल जाएगा इसलिए सजा कम कर
                    दी जाए उसके बाद उन्होंने निश्चय किया कि इस प्रकार की समस्या का स्थाई समाधान किया जाए उस समाधान के
                    लिए उन्होंने उन्होंने बाहर से आकर बसने वाले व्यक्ति को हर परिवार से एक ईट तथा ₹1 दान देने का आदेश
                    दिया हर परिवार उसे एक एक ईट देता तो उसके पास एक लाख ईट जमा हो जाती जिससे वह अपने घर का निर्माण कर
                    सकता था तथा एक एक रुपए से उसके पास 1 लाख रुपए की पूंजी इकट्ठी हो जाती जिससे वह बड़े आराम से अपना
                    व्यापार शुरू कर सकता था यह राशि कर्ज के रूप में नहीं होती थी इस पर किसी प्रकार का ब्याज भी नहीं था
                    वह देने वाले का कोई एहसान भी नहीं था अतः उसे न ब्याज की चिंता थी नहीं मूल रकम की इस व्यवस्था से कोई
                    भी बाहर से आने वाला व्यक्ति पूरे स्वाभिमान के साथ अपना व्यवसाय शुरु करता एवं उसको निरंतर आगे बढ़ाता
                    है
                    अग्रकुल संस्थापक महाराजा श्री अग्रसेन जी के जीव प्रेम एवं अहिंसा के दृढ़ संकल्प के परिणाम स्वरुप ही
                    उनके 18 वे यज्ञ को पशु बलि के स्थान पर श्रीफल से पूर्ण किया गया क्षत्रिय कुल का यज्ञ तो बली से ही
                    पूरे होते थे लेकिन वैश्य कुल के लोग खाद्य पदार्थों की आहुति देकर भी यज्ञ पूर्ण कर सकते हैं तत्पश्चात
                    महाराजा अग्रसेन ने विचार किया उनके मन में विचार आया इसी कारण उन्होंने क्षत्रिय वर्ण से के स्थान पर
                    वैश्य वर्ण को अपना लिया एक और विचार किया कि वैश्य वर्ण संपूर्ण अर्थव्यवस्था व संपूर्ण समाज के लिए
                    काम करता है वैश्य वर्ण से बाकी तीनों वर्ण पोषित हैं राज्य कार्य वैश्य वर्ण द्वारा दिए गए कर से पुरे
                    होते हैं ब्राह्मणों का ज्ञान बांटने का कार्य भी वैश्य वर्ग द्वारा उनको दिए गए दान से ब्राह्मण वर्ग
                    के लालन-पालन की व्यवस्था होती है एवं शूद्र वर्ग भी रोजगार के लिए वैश्य वर्ग का ही सहारा लेता है
                    अर्थात जो सारी आर्थिक गतिविधि जो व्यक्ति करता है और वैश्य कहलाता है वैश्य शब्द जाति से संबंध रखता
                    बल्कि कार्य संबंध रखता है उसी प्रकार शूद्र वर्ण का अर्थ निम्न कार्य जाति से नहीं था उनके अनुसार उस
                    समय सेवा के बदले मूल्य लेता था चाहे वह कितना बड़ा अधिकारी हो अथवा कोई भी व्यक्ति हो जो भी सेवा के
                    बदले मूल्य लेगा वह शूद्र कहलाता था
                    इस प्रकार महाराजा अग्रसेन अपना राज्य करते हुए 108 वर्ष के हो गए तो उन्होंने संन्यास लेने की सोची
                    उनका सन्यास का मन हुआ तो उन्होंने अपने 18 पुत्रों को इकट्ठा पर विचार विमर्श किया और उन्होंने अपने
                    राज्य का 18 भागों में विभाजित कर दिया हर पुत्र को एक एक राज्य दे दिया अपने राज्य को गणों मैं बांटकर
                    गणराज्य बनाया उनके 18 पुत्रों की के नाम पर ही अग्रवालो के18 गोत्रों की स्थापना हुई थी
                    वनवास जाते समय उन्होंने मां लक्ष्मी की प्रार्थना करी मां लक्ष्मी ने प्रकट होकर पूछा तुम्हें क्या
                    चाहिए तो अग्रकुल प्रवर्तक महाराजा श्री अग्रसेन ने मांगा कि मां यह वरदान दीजिए कि जो भी व्यक्ति
                    परोपकार के लिए स्वरोजगार से धन कमाएगा उस पर आपकी कृपा हमेशा बनी रहेगी महालक्ष्मी ने कहा कि मैं
                    तुम्हारे को यह वरदान देती हूं
                    इस प्रकार उन्होंने धन का उपयोग केवल परोपकार बताया
                    अग्रवाल लोग यह अहंकार न रखें कि अग्रसेन जी के वंशज होने के कारण उन पर लक्ष्मी प्रसन्न है जो अग्रवाल
                    धन के कारण कोई अहंकार नहीं करते कोई पाप नहीं करते शुद्ध कमाई करते हैं और अपने जीवन पर साधारण खर्च
                    करके अपना पैसा परोपकार में खर्च करते हैं उन पर लक्ष्मी जी की कृपा रहती है महाराज श्री अग्रसेन जी तो
                    लाभ से पहले शुभ चाहते थे इसलिए वैश्य वर्ग अपने व्यापारी स्थान पर पहले शुभ बाद में लाभ लिखता है

                </p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="row g-3">
                <div class="col-sm-6">
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गर्ग &nbsp;(Garg or Gargeya)
                    </h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गोयल (Goyal, Goel or Goenka)
                    </h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>जिंदल (Jindal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बिंदल (Bindal or Vindal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कंदल &nbsp;(Kandal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बंसल (Bansal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>जिंजल (Jinjal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बिंछल (Binchhal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कांसल (Kansal)</h6>
                </div>
                <div class="col-sm-6">
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>भेदल &nbsp;(Bhedal / Bhandal)
                    </h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>मंगल (Mangal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>मित्तल (Mittal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>सिंगल (Singal, Singhal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>एरण (Airan/Aeron)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>नांगल (Nangal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कुंच्छल (Kuchhal / Kuchchal)
                    </h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>धारण (Dharan or Deran)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गोइन (Goin, Goyan, Gangal)
                    </h6>
                </div>
            </div>
        </div>

    </div>
</div>