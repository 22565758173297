import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';
import { WindowRefServiceService } from 'src/app/services/window-ref-service.service';


@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  providers: [WindowRefServiceService]
})
export class RegisterFormComponent implements OnInit {
  isindevidualShow: boolean = false;

  husbandorwifesShow: boolean = false;
  stateShow: boolean = false;
  cityShow: boolean = false;
  cityotherShow: boolean = false;


  isReady: boolean = false;
  isState: boolean = false;

  getinForm: FormGroup;
  viewModal: boolean = false;
  favoriteSeason: string = '';
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  navbarOpen = false;
  fileData: any = null;

  countryArr: any = [];
  stateArr: any = [];
  cityArr: any = [];

  constructor(private winRef: WindowRefServiceService, private elementRef: ElementRef, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private notify: NotifyService, public mainservice: MainService) {
    this.getinForm = this.formBuilder.group({
      member: new FormControl('', Validators.required),
      gotra: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      fname: new FormControl('', Validators.required),
      email: new FormControl(''),
      //[Validators.required, Validators.email]
      husbandorwife: new FormControl(''),
      dob: new FormControl('', Validators.required),
      country_id: new FormControl('', Validators.required),
      state_id: new FormControl(''),
      city_id: new FormControl(''),
      city_other: new FormControl(''),

      kutumid: new FormControl(''),
      mobile: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')
        , Validators.minLength(10), Validators.maxLength(10)]),
      address: new FormControl('', Validators.required),
      pincode: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.minLength(4), Validators.maxLength(6)]),
      payment_mode: new FormControl('', Validators.required),
      martial_status: new FormControl('', Validators.required),
      payment_type: new FormControl(''),
      tnc: new FormControl('', Validators.required),
      // idproof: new FormControl(''),
    });


  }
  get gf(): any {
    return this.getinForm['controls'];
  }


  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }

  onFileChange(event: any, imageType: any) {
    if (event.target.files && event.target.files.length > 0) {
      if (["image/jpg", "image/jpeg", "image/png"].includes(event.target.files[0].type)) {
        let input = new FormData();
        if (imageType == "profile") {
          let fileSelected: File = event.target.files[0];
          input.append('profile', fileSelected);
          this.fileData = input;
          var reader = new FileReader();
          reader.readAsDataURL(fileSelected);
          reader.onload = (_event) => {
          }
        }

        if (imageType == "idproof") {
          let fileSelected: File = event.target.files[0];
          input.append('idproof', fileSelected);
          this.fileData = input;
          var reader = new FileReader();
          reader.readAsDataURL(fileSelected);
          reader.onload = (_event) => {
          }
        }
      }
    }
  }





  submit() {
    // this.mainservice.registerForm(this.fileData).subscribe(
    //   response => { 
    //     window.location.href = response.StatusCode;

    //     this.notify.showSuccess(response.message, 'Success !!');
    //   },
    //   error => {
    //     this.notify.showError(error.error['message'], 'Error !!');
    //   }
    // );
    // return false;
    let arr: any = { 1: 100, 2: 200, 3: 1100, 4: 1100, 5: 1600, 6: 11000, 7: 11000, 8: 16000 };
    // console.log('this.getinForm', ); 
    if (this.getinForm.valid) {
      this.isReady = true;
      let city_oth = this.gf.city_id.value == 'other' ? this.gf.city_other.value : this.gf.country_id.value == 'other' ? this.gf.city_other.value : '';

      if (this.fileData == null) {
        let input = new FormData();
        input.append("profile", '');
        input.append("idproof", '');
        input.append("member", this.gf.member.value);
        input.append("gotra", this.gf.gotra.value);
        input.append("name", this.gf.name.value);
        input.append("fname", this.gf.fname.value);
        input.append("email", this.gf.email.value);
        input.append("husbandorwife", this.gf.husbandorwife.value);
        input.append("dob", this.gf.dob.value);
        input.append("kutumid", this.gf.kutumid.value);
        input.append("mobile", this.gf.mobile.value);
        input.append("address", this.gf.address.value);
        input.append("pincode", this.gf.pincode.value);
        input.append("payment_mode", this.gf.payment_mode.value);
        input.append("payment_type", this.gf.payment_type.value);
        input.append("martial_status", this.gf.martial_status.value);
        input.append("country_id", this.gf.country_id.value);
        input.append("state_id", this.gf.state_id.value);
        input.append("city_id", this.gf.city_id.value);
        input.append("city_other", city_oth);
        input.append("tnc", this.gf.tnc.value);
        this.fileData = input;
      } else {
        this.fileData.append("member", this.gf.member.value);
        this.fileData.append("gotra", this.gf.gotra.value);
        this.fileData.append("name", this.gf.name.value);
        this.fileData.append("fname", this.gf.fname.value);
        this.fileData.append("email", this.gf.email.value);
        this.fileData.append("husbandorwife", this.gf.husbandorwife.value);
        this.fileData.append("dob", this.gf.dob.value);
        this.fileData.append("kutumid", this.gf.kutumid.value);
        this.fileData.append("mobile", this.gf.mobile.value);
        this.fileData.append("address", this.gf.address.value);
        this.fileData.append("pincode", this.gf.pincode.value);
        this.fileData.append("payment_mode", this.gf.payment_mode.value);
        this.fileData.append("payment_type", this.gf.payment_type.value);
        this.fileData.append("martial_status", this.gf.martial_status.value);
        this.fileData.append("country_id", this.gf.country_id.value);
        this.fileData.append("state_id", this.gf.state_id.value);
        this.fileData.append("city_id", this.gf.city_id.value);
        this.fileData.append("tnc", this.gf.tnc.value);
        this.fileData.append("city_other", city_oth);
      }
      console.log('FF', JSON.stringify(this.fileData));
      this.mainservice.registerForm(this.fileData).subscribe(
        response => {
          this.isReady = false;
          if (response.status == 'error') {
            this.notify.showError(response.message, 'Error !!');
          } else {
            if (this.gf.payment_mode.value == 1) {
              this.payWithRazor(response.bookings, arr[this.gf.member.value]);
            } else {
              this.notify.showSuccess(response.message, 'Success !!');
              this.router.navigate(['/download']);
            }
          }

        },
        error => {
          this.isReady = false;
          this.notify.showError(error.error['message'], 'Error !!');
        }
      );
    } else {
      this.isReady = false;
      this.notify.showError('Please select all information', 'Error !!');
    }
  }



  indType() {
    if (this.gf.payment_mode.value == 2) {
      this.isindevidualShow = true;
    } else {
      this.isindevidualShow = false;
    }
  }


  countryType(e: any) {
    if (e.target.value) {
      if (e.target.value == 'other') {
        this.getinForm.controls['city_other'].setValidators([Validators.required]);
        this.cityotherShow = true;
      } else {
        this.getinForm.controls['city_other'].clearValidators();
        this.isState = true;
        let data = {
          'country_id': e.target.value,
        }
        this.getinForm.controls['state_id'].setValidators([Validators.required]);
        this.mainservice.getState(data).subscribe(
          (res: any) => {
            this.stateArr = res['data'];
            this.isState = false;
          });
        this.stateShow = true;

      }
    } else {
      this.getinForm.controls['state_id'].clearValidators();
      this.isState = false;
      this.stateShow = false;
    }
  }

  stateType(e: any) {
    this.cityShow = false;
    this.isState = true;
    if (e.target.value) {
      let data = {
        'state_id': e.target.value,
      }
      this.getinForm.controls['city_id'].setValidators([Validators.required]);
      this.mainservice.getCity(data).subscribe(
        (res: any) => {
          this.cityArr = res['data'];
          let otherarr = {
            id: 'other',
            name: "Other",
            pincode: null,
            state_id: '',
            updated_at: null
          }
          this.cityArr.push(otherarr);
          this.cityShow = true;
          this.isState = false;
        });

    } else {
      this.getinForm.controls['city_id'].clearValidators();
      this.isState = false;
      this.cityShow = false;
    }
  }


  cityType(e: any) {
    if (e.target.value) {
      if (e.target.value == 'other') {
        this.getinForm.controls['city_other'].setValidators([Validators.required]);
        // this.getinForm.addControl('city_other', new FormControl('', Validators.required));
        this.cityotherShow = true;
      } else {
        this.getinForm.controls['city_other'].clearValidators();
        this.cityotherShow = false;
      }
    } else {
      this.getinForm.controls['city_other'].clearValidators();
      this.cityotherShow = false;
    }
  }

  husbandorwifes(e: any) {
    if (e.target.value == 1) {
      this.getinForm.controls['husbandorwife'].setValidators([Validators.required]);
      // this.getinForm.addControl('husbandorwife', new FormControl('', Validators.required));
      this.husbandorwifesShow = true;
    } else {
      this.getinForm.controls['husbandorwife'].clearValidators();
      // this.getinForm.removeControl('husbandorwife');
      this.husbandorwifesShow = false;
    }
  }

  payWithRazor(order_id: any, amount: any) {
    const options: any = {
      key: 'rzp_live_ktlOkCjA20GTNU',
      amount: amount * 100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: '', // company name or product name
      description: '',  // product description 
      order_id: order_id, // order_id created by you in backend
      // prefill: {
      //   email: (this.users) ? this.users.email : '',
      //   contact: (this.users) ? this.users.phone : '',
      // },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      },
      // "prefill": {
      //   "name": 'sdf',
      //   "email": 'dsfdsf@ff.vv',
      //   "contact": '987654'
      // },
    };
    options.handler = ((response: any, error: any) => {
      options.response = response;
      console.log('response', response);
      if (response) {
        let pay = {
          'paymentId': response.razorpay_payment_id,
          'orderId': response.razorpay_order_id,
          'signature': response.razorpay_signature,
          'type': 1
        }
        // this.spinner.show();
        this.mainservice.verifyMember(pay).subscribe((res: any) => {
          // this.spinner.hide();
          // if (res['data'] != '') {
          this.notify.showSuccess(res.message, 'Success !!');
          this.router.navigate(['/download']);
          // }
        }, (error) => {
          // this.spinner.hide();
          this.notify.showError(error.error['message'], 'Error !!');
        });
        // location.reload();
        // this.router.navigate(['/myaccount/my-subscription']);
      }
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  submitss() {
    let data = {
      'name': this.gf.name.value,
      'email': this.gf.email.value,
      'mobile': this.gf.mobile.value,
      'message': this.gf.message.value
    }
    // console.log('message', data);
    // this.mainservice.ContactForms(data).subscribe((res: any) => {
    //   if (res['success']) {
    //     this.getinForm.reset();
    //     this.notify.showSuccess(res['message'], "Success !!");
    //   } else {
    //     this.notify.showError(res['message'], "Failure !!");
    //   }
    // });
  }


  ngOnInit(): void {
    this.mainservice.getCountry().subscribe(
      (res: any) => {
        this.countryArr = res['data'];
        let otherarr = {
          id: 'other',
          iso: "Other",
          iso3: "Other",
          name: "OTHER",
          nicename: "India",
          numcode: "356",
          phonecode: "91"
        }
        this.countryArr.push(otherarr);
      });
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://checkout.razorpay.com/v1/checkout.js";
    this.elementRef.nativeElement.appendChild(s);
  }

}
