import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss']
})
export class EditPageComponent implements OnInit {
  EditPage: FormGroup;
  fileToUpload: any;
  banner: any;
  thumbnail: any;
  pageDetail: any=[];
  page_id: any;
  subType: any=[];
  pageList: Array<any> = [
    { name: 'service', subType: ['business', 'accountancy'] },
    { name: 'program', subType: ['citizenship'] },
  ];
  constructor(public router:Router,formBuilder : FormBuilder,public mainservice:MainService,private notificationService:NotifyService) {
    this.EditPage=formBuilder.group({
      page_name:new FormControl('', Validators.required),
      description:new FormControl('', Validators.required),
      page_title:new FormControl('', Validators.required),
      type:new FormControl('', Validators.required),
      sub_type:new FormControl('', Validators.required),
      heading:new FormControl(''),
      banner:new FormControl(''),
      seo_url:new FormControl(''),
      sort:new FormControl('',[Validators.pattern('[- +()0-9]+')]),
      meta_title:new FormControl(''),
      meta_keyword:new FormControl(''),
      meta_desc:new FormControl(''),
    });
  }
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
};
  get bf() { return this.EditPage['controls'] }
  changeCountry(count:any) {
    this.subType = this.pageList.find(con => con.name == count.value).subType;
  }
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        // const max_size = 20971520;
        // const allowed_types = ['image/png', 'image/jpeg'];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.banner  = e.target.result;
        };
        reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  thumbnailFileInput(file: any) {
    this.fileToUpload = file.item(0);
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.thumbnail = event.target.result;
      console.log("imageUrl",this.thumbnail);
    };
    reader.readAsDataURL(this.fileToUpload);
  }
  submit(){
    let submitdata = {
      'page_id':this.page_id,
      'page_name' : this.bf.page_name.value,
      'desc': this.bf.description.value,
      'page_title' : this.bf.page_title.value,
      'banner_img' : (this.banner) ? this.banner : '',
      'sort' : this.bf.sort.value,
      'type' : this.bf.type.value,
      'sub_type' : this.bf.sub_type.value,
      'heading' : this.bf.heading.value,
      'url' : this.bf.seo_url.value,
      'meta_title' : this.bf.meta_title.value,
      'meta_keyword' : this.bf.meta_keyword.value,
      'meta_desc' : this.bf.meta_desc.value,
    }
    console.log("this",submitdata);
    this.mainservice.editPage(submitdata).subscribe((res:any)=>{
      if(res['success']){
        //this.EditPage.reset();
        this.getDetail();
        this.notificationService.showSuccess(res['message'], "Success !!");
        //alert('success');
      }else{
        this.notificationService.showError(res['message'], "Failure !!");
      }
    });
  }
  back(){
    this.mainservice.checkType = 1;
  }
  setData(){
    this.EditPage.controls['page_title'].setValue(this.pageDetail.page_title);
    this.EditPage.controls['page_name'].setValue(this.pageDetail.page_name);
    this.EditPage.controls['description'].setValue(this.pageDetail.desc);
    this.EditPage.controls['sort'].setValue(this.pageDetail.sort);
    this.EditPage.controls['type'].setValue(this.pageDetail.type);
    this.changeCountry(this.bf.type);
    this.EditPage.controls['sub_type'].setValue(this.pageDetail.sub_type);
    this.EditPage.controls['heading'].setValue(this.pageDetail.heading);
    this.EditPage.controls['meta_desc'].setValue(this.pageDetail.meta_desc);
    this.EditPage.controls['meta_keyword'].setValue(this.pageDetail.meta_keyword);
    this.EditPage.controls['meta_title'].setValue(this.pageDetail.meta_title);
    this.EditPage.controls['seo_url'].setValue(this.pageDetail.url);
  }
  getDetail(){
    this.mainservice.pageDetail(this.page_id).subscribe((res:any)=>{
      if(res['success']){
        this.pageDetail= res['pageDetail'];
        console.log(this.pageDetail)
        this.setData();
      }else{
        this.notificationService.showError(res['message'], "Failure !!");
      }
    });
  }
  ngOnInit(): void {
    this.page_id=sessionStorage.getItem('page_id');
    if(this.page_id){
      this.getDetail();
    }
  }
}