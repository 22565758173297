import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
  allBlogs: any=[];

  constructor(public router:Router,public mainservice:MainService,private notificationService:NotifyService) { }

  blog(){
    this.mainservice.allblogs('').subscribe((blog:any)=>{
      if(blog['success']){
        this.allBlogs =blog['blogs'];
        //  console.log("blogs",this.allBlogs);
      }else{
        this.notificationService.showError(blog['message'], "Failure !!");
      }
    });
  }
  ngOnInit() {
    this.blog();
  }

}
