import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutAgarwalComponent } from './components/layouts/main-layout/about-agarwal/about-agarwal.component';
import { AboutUsComponent } from './components/layouts/main-layout/about-us/about-us.component';
import { AdminLoginComponent } from './components/layouts/main-layout/admin-login/admin-login.component';
import { BlogDetailComponent } from './components/layouts/main-layout/blog-detail/blog-detail.component';
import { BlogsComponent } from './components/layouts/main-layout/blogs/blogs.component';
import { BookUserComponent } from './components/layouts/main-layout/book-user/book-user.component';
import { ContactUsComponent } from './components/layouts/main-layout/contact-us/contact-us.component';
import { DownloadCertificateComponent } from './components/layouts/main-layout/download-certificate/download-certificate.component';
import { GalleryComponent } from './components/layouts/main-layout/gallery/gallery.component';
import { MainLayoutComponent } from './components/layouts/main-layout/main-layout.component';
import { NotFoundComponent } from './components/layouts/main-layout/not-found/not-found.component';
import { PrivacyComponent } from './components/layouts/main-layout/privacy/privacy.component';
import { RefundPolicyComponent } from './components/layouts/main-layout/refund-policy/refund-policy.component';
import { RegisterBookComponent } from './components/layouts/main-layout/register-book/register-book.component';
import { RegisterFormComponent } from './components/layouts/main-layout/register-form/register-form.component';
import { ServicePageComponent } from './components/layouts/main-layout/service-page/service-page.component';
import { TermComponent } from './components/layouts/main-layout/term/term.component';
import { AuthGuard } from './guard/auth.guard';
import { MetrominialFormComponent } from './components/layouts/main-layout/metrominial-form/metrominial-form.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full'
  // },
  { path: '', component: MainLayoutComponent },
  // { path: 'blogs', component: BlogsComponent },
  // { path: 'blog/:id', component: BlogDetailComponent },
  { path: 'service/:id', component: ServicePageComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'about-maharaj', component: AboutAgarwalComponent },
  { path: 'register-form', component: RegisterFormComponent },
  { path: 'register-book', component: RegisterBookComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'book-user', component: BookUserComponent },
  { path: 'metrominial-form', component: MetrominialFormComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'admin-login', component: AdminLoginComponent },
  { path: 'member', component: ServicePageComponent },
  { path: 'download', component: DownloadCertificateComponent },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard] },
  { path: '404', component: NotFoundComponent },
  { path: 'term-conditions', component: TermComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
