<!-- Navbar & Hero Start -->
<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn"> सदस्यता फार्म</h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Navbar & Hero End -->


<!-- <section class="banner-color">
    <div class="container margin-horizontal py-5">
        <div class="row py-5">
            <div class="col-12 py-5"> 
<h2 class="text-uppercase text-white text-center font-size-64 mb-4 mb-sm-5">
    सदस्यता फार्म
</h2>
<p class="text-center text-white">में अखिल भारतीय अग्रवाल महासभा का पेटर्न मेंबर (संरक्षक) / आजीवन सदस्य
    / तीन वर्षीय सदस्य बनना चाहता हु / चाहती हु | संस्था के नियमानुसार अपनी निजी जानकारी का विवरण ऑनलाइन
    फॉर्म के माध्यम से भर रहा हु / भर रही हु | जो की मेरे द्वारा पूर्ण रूप से सत्यापित है | </p>
</div>
</div>
</div>
</section> -->
<section style="background-color: #F8F9FC;">
    <div class="container py-5 mt-3 mt-md-5">
        <div class="row align-items-center ">
            <div class="col-12">
                <div class="wow fadeInUp" data-wow-delay="0.3s">
                    <!-- <form>
                        <script src="https://checkout.razorpay.com/v1/payment-button.js"
                            data-payment_button_id="pl_IYCZeiKqwYpDR0" async> </script>
                    </form> -->
                    <form [formGroup]="getinForm" (ngSubmit)="submit()">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <select type="text" formControlName="member" class="form-control">
                                        <option value="">सदस्यता ( Membership of Akhil Bhartiya Agrawal Mahasabha )
                                        </option>
                                        <option value="1">तीन वर्षीय महिला सदस्य (रुपया 100)</option>
                                        <option value="2">तीन वर्षीय पुरुष सदस्य (रुपया 200)</option>
                                        <option value="3">आजीवन महिला सदस्य (रुपया 1,100)</option>
                                        <option value="4">आजीवन पुरुष सदस्य (रुपया 1,100)</option>
                                        <option value="5">आजीवन सदस्य युगल ( जोड़ा ) (रुपया 1,600)</option>
                                        <option value="6">पेटर्न मेंबर (संरक्षक) महिला सदस्य (रुपया 11,000)
                                        </option>
                                        <option value="7">पेटर्न मेंबर (संरक्षक) पुरुष सदस्य (रुपया 11,000)
                                        </option>
                                        <option value="8">पेटर्न मेंबर (संरक्षक) युगल ( जोड़ा ) (रुपया 16,000)
                                        </option>
                                    </select>
                                    <label for="name">सदस्यता प्रकार <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.member.hasError('required') && (gf.member.dirty || gf.member.touched)">
                                        कृपया सदस्यता चुने</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <select type="text" formControlName="gotra" class="form-control">
                                        <option value="">गोत्र</option>
                                        <option value="garg">गर्ग &nbsp;(Garg or Gargeya)</option>
                                        <option value="goyal">गोयल (Goyal, Goel or Goenka)</option>
                                        <option value="Jindal">जिंदल (Jindal)</option>
                                        <option value="Bindal">बिंदल (Bindal or Vindal)</option>
                                        <option value="Kandal">कंदल &nbsp;(Kandal)</option>
                                        <option value="Bansal">बंसल (Bansal)</option>
                                        <option value="Jinjal">जिंजल (Jinjal)</option>
                                        <option value="Binchhal">बिंछल (Binchhal)</option>
                                        <option value="Kansal">कांसल (Kansal)</option>
                                        <option value="Bhedal">भेदल &nbsp;(Bhedal / Bhandal)</option>
                                        <option value="Mangal">मंगल (Mangal)</option>
                                        <option value="Mittal">मित्तल (Mittal)</option>

                                        <option value="Singal">सिंगल (Singal, Singhal)</option>
                                        <option value="Airan">एरण (Airan/Aeron)</option>
                                        <option value="Nangal">नांगल (Nangal)</option>
                                        <option value="Kuchhal">कुंच्छल (Kuchhal / Kuchchal)</option>
                                        <option value="Tayal">तायल (Tayal)</option>
                                    </select>
                                    <label for="email">गोत्र <span class="text-danger">*</span> </label>
                                </div>

                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.gotra.hasError('required') && (gf.gotra.dirty || gf.gotra.touched)">
                                        कृपया गोत्र चुने</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" formControlName="name" class="form-control" id="name"
                                        placeholder="पूरा नाम">
                                    <label for="email">पूरा नाम <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.name.hasError('required') && (gf.name.dirty || gf.name.touched)">
                                        कृपया पूरा नाम भरें</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="fname" id="name"
                                        placeholder="पिता का नाम">
                                    <label for="email">पिता का नाम <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.fname.hasError('required') && (gf.fname.dirty || gf.fname.touched)">
                                        कृपया पिता का नाम भरें</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <select type="text" formControlName="martial_status" class="form-control"
                                        (change)="husbandorwifes($event)">
                                        <option value="">वैवाहिक स्थिति चुने
                                        </option>
                                        <option value="1">विवाहित</option>
                                        <option value="2">अविवाहित</option>
                                    </select>
                                    <label for="name">वैवाहिक स्थिति <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.martial_status.hasError('required') && (gf.martial_status.dirty || gf.martial_status.touched)">
                                        वैवाहिक स्थिति चुने</div>
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="husbandorwifesShow">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" formControlName="husbandorwife"
                                        placeholder="पती / पत्नी का नाम">
                                    <label for="email">पती / पत्नी का नाम <span class="text-danger"> * </span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.husbandorwife.hasError('required') && (gf.husbandorwife.dirty || gf.husbandorwife.touched)">
                                        कृपया पती / पत्नी का नाम भरें</div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-floating">
                                    <input type="date" class="form-control" formControlName="dob" />
                                    <!-- <input type="text" class="form-control" id="subject" placeholder="Subject"> -->
                                    <label for="subject">जन्मतिथि <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.dob.hasError('required') && (gf.dob.dirty || gf.dob.touched)">
                                        कृपया जन्मतिथि भरें</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" formControlName="kutumid"
                                        placeholder="कुटुम एप आईडी">
                                    <label for="email">कुटुम एप आईडी</label>
                                </div>
                                <!-- <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.kutumid.hasError('required') && (gf.kutumid.dirty || gf.kutumid.touched)">
                                        कृपया कुटुम एप आईडी भरें</div>
                                </div> -->
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <!-- <div Class="text-danger text-left">
                                        <div *ngIf="gf.name.hasError('required') && (gf.name.dirty || gf.name.touched)">
                                            Please enter Full Name</div>
                                    </div> -->
                                    <input type="text" class="form-control" id="name" placeholder=" मोबाइल नंबर"
                                        formControlName="mobile" maxlength="10">
                                    <label for="email"> मोबाइल नंबर <span class="text-danger">*</span></label>
                                </div>
                                <!-- <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.mobile.hasError('required') && (gf.mobile.dirty || gf.mobile.touched)">
                                        कृपया मोबाइल नंबर भरें</div>
                                </div> -->

                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.mobile.hasError('required') && (gf.mobile.dirty || gf.mobile.touched)">
                                        कृपया मोबाइल नंबर भरें</div>
                                    <ng-container *ngIf="gf.mobile.hasError('pattern'); else np;">
                                        <div>Only numbers allowed.</div>
                                    </ng-container>
                                    <ng-template #np>
                                        <div *ngIf="gf.mobile.hasError('minlength') ">Phone number should contains
                                            atleast 10 digits.</div>
                                        <div *ngIf=" gf.mobile.hasError('maxlength')">Phone number should not exceed
                                            10 digits.</div>
                                    </ng-template>
                                </div>

                            </div>


                            <div class="col-md-6">
                                <div class="form-floating">
                                    <select type="text" formControlName="payment_mode" class="form-control"
                                        (change)="indType()">
                                        <option value="">पेमेंट मोड़ चुने
                                        </option>
                                        <option value="1">ऑनलाइन मोड़</option>
                                        <option value="2">ऑफलाइन मोड़</option>
                                    </select>
                                    <label for="name">पेमेंट मोड़ <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.payment_mode.hasError('required') && (gf.payment_mode.dirty || gf.payment_mode.touched)">
                                        पेमेंट मोड़ चुने</div>
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="isindevidualShow">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="payment_type" id="name"
                                        placeholder="कैश / चेक / डीडी">
                                    <label for="email"> कैश / चेक / डीडी </label>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" placeholder="ईमेल आईडी"
                                        formControlName="email">
                                    <label for="email"> ईमेल आईडी</label>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="file" class="form-control" (change)="onFileChange($event, 'profile')">
                                    <label for="email">फोटो (खुद की पासपोर्ट साइज)</label>
                                </div>
                                <!-- <div Class="text-danger text-left">
                                    <div *ngIf="gf.profile.hasError('required') && (gf.profile.dirty || gf.profile.touched)">
                                        कृपया फोटो (खुद की पासपोर्ट साइज) चुने</div>
                                </div> -->
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="file" class="form-control" (change)="onFileChange($event, 'idproof')">
                                    <label for="email">पहचान पत्र (ID Proof)</label>
                                </div>
                                <!-- <div Class="text-danger text-left">
                                        <div *ngIf="gf.idproof.hasError('required') && (gf.idproof.dirty || gf.idproof.touched)">
                                            कृपया पहचान पत्र (ID Proof) चुने</div>
                                    </div> -->
                            </div>



                            <div class="col-6">
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="पूरा पता" id="message"
                                        formControlName="address" style="height:100px"></textarea>
                                    <label for="message">पूरा पता <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.address.hasError('required') && (gf.address.dirty || gf.address.touched)">
                                        कृपया पूरा पता भरें</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <!-- <div Class="text-danger text-left">
                                        <div *ngIf="gf.name.hasError('required') && (gf.name.dirty || gf.name.touched)">
                                            Please enter Full Name</div>
                                    </div> -->
                                    <input type="text" class="form-control" id="name" placeholder="पिन कोड नंबर"
                                        formControlName="pincode" maxlength="6">
                                    <label for="email">पिन कोड नंबर <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.pincode.hasError('required') && (gf.pincode.dirty || gf.pincode.touched)">
                                        कृपया पिन कोड नंबर भरें</div>
                                    <ng-container *ngIf="gf.pincode.hasError('pattern'); else pin;">
                                        <div>Only numbers allowed.</div>
                                    </ng-container>
                                    <ng-template #pin>
                                        <div *ngIf="gf.pincode.hasError('minlength') ">Pincode should contains
                                            atleast 4 digits.</div>
                                        <div *ngIf=" gf.pincode.hasError('maxlength')">Pincode should not exceed
                                            6 digits.</div>
                                    </ng-template>
                                </div>


                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <select type="text" formControlName="country_id" class="form-control"
                                        (change)="countryType($event)">
                                        <option value="">देश चुने
                                        </option>
                                        <option *ngFor="let rel of this.countryArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">देश <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.country_id.hasError('required') && (gf.country_id.dirty || gf.country_id.touched)">
                                        देश चुने</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="stateShow">
                                <div class="form-floating">
                                    <select type="text" formControlName="state_id" class="form-control"
                                        (change)="stateType($event)">
                                        <option value="">राज्य चुने
                                        </option>
                                        <option *ngFor="let rel of this.stateArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">राज्य <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.state_id.hasError('required') && (gf.state_id.dirty || gf.state_id.touched)">
                                        राज्य चुने</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="cityShow">
                                <div class="form-floating">
                                    <select type="text" formControlName="city_id" class="form-control"
                                        (change)="cityType($event)">
                                        <option value="">शहर चुने
                                        </option>
                                        <option *ngFor="let rel of this.cityArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">शहर <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.city_id.hasError('required') && (gf.city_id.dirty || gf.city_id.touched)">
                                        शहर चुने</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="cityotherShow">
                                <div class="form-floating">
                                    <input type="text" formControlName="city_other" class="form-control" id="name"
                                        placeholder="अन्य शहर">
                                    <label for="email">अन्य शहर <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.city_other.hasError('required') && (gf.city_other.dirty || gf.city_other.touched)">
                                        अन्य शहर</div>
                                </div>
                            </div>


                            <!-- <div class="col-12" *ngIf="isindevidualShow">
                                <div class="form-group"
                                    [ngClass]="{ 'was-validated': sf.vendorType.valid &&  sf.vendorType.touched}">
                                    <select type="text" formControlName="vendorType" class="form-control"
                                        [ngClass]="{ 'is-invalid': sf.vendorType.invalid && (sf.vendorType.dirty || sf.vendorType.touched) }"
                                        required>
                                        <option value="">Select Vendor Type</option>
                                        <option *ngFor="let rel of this.vendorArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <div Class="text-danger text-left">
                                        <div
                                            *ngIf="sf.vendorType.invalid && (sf.vendorType.dirty || sf.vendorType.touched)">
                                            Please select Vendor Type.</div>
                                    </div>
                                </div>
                            </div>
                             -->

                        </div>

                        <div class="col-12 text-left">
                            <div class="form-check mx-0 mx-md-2 text-left">
                                <input type="checkbox" formControlName="tnc" class="form-check-input" id="tnc">
                                <span class="pointer">
                                    I Agree to terms and conditions
                                </span>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-6">
                                <button [disabled]="getinForm.invalid" class="btn btn-primary w-100 py-3"
                                    type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="overlay text-center" *ngIf="isReady==true">
    <div class="text">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="overlay text-center" *ngIf="isState==true">
    <div class="text">
        <mat-spinner></mat-spinner>
    </div>
</div>

<!-- <section>
    <div class="container">
        <div class="row align-items-center py-3 py-md-0">
            <div class="col-md-6">
                <h1>
                    Make the Shift Today
                </h1>
                <h3 class="pr-0 pr-md-3">
                    Get a personalized tour and learn how you can start accelerating your growth through sophisticated
                    omnichannel activation on the world’s first Customer Data Activation Platform (CDAP).
                </h3>
                <button class="btn btn-sub mt-3 " (click)="showModal()">
                    SCHEDULE DEMO
                </button>
            </div>
            <div class="col-md-6 text-right">
                <img src="assets/images/last_section_img.png" class="w-100 img-fluid">
            </div>
        </div>
    </div>
</section> -->



<!-- <div class="modal" id="myModal" [style.display]="viewModal ? 'block' : 'none'" style="backdrop-filter:
brightness(0.4);">
    <div class="modal-dialog modal-md" style="margin-top: 3rem;">
        <div class="modal-content p-2">
            <div class="modal-header text-right p-0 border-0">
                <button (click)="hide()" type="button" class="hide" data-dismiss="modal" aria-label="Close">
                    &times;
                </button>
            </div>
            <div class="modal-body text-center p-0">
                <div class="row mx-3">
                    <div class="col-12">
                        <form [formGroup]="demoForm" (ngSubmit)="Demosubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2>SCHEDULE YOUR DEMO</h2>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Full Name</mat-label>
                                            <input matInput placeholder="Placeholder" formControlName="name">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.name.hasError('required') && (df.name.dirty || df.name.touched)">
                                                Please enter Full Name</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email Address</mat-label>
                                            <input matInput type="email" placeholder="Placeholder"
                                                formControlName="email">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.email.hasError('required') && (df.email.dirty || df.email.touched)">
                                                Please enter email
                                            </div>
                                            <div *ngIf="df.email.hasError('email') ">Only email formate allowed</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Phone Number</mat-label>
                                            <input matInput placeholder="Placeholder" formControlName="mobile">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.mobile.hasError('required') && (df.mobile.dirty || df.mobile.touched)">
                                                Please enter Mobile No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" formControlName="service">
                                            <option disabled selected value="">Select Service</option>
                                            <option value="s">ss</option>
                                        </select>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.service.hasError('required') && (df.service.dirty || df.service.touched)">
                                                Please enter message</div>
                                        </div>

                                    </div>
                                    <div class="form-group text-center mt-4">
                                        <button type="submit" class="btn btn-sub" [disabled]="demoForm.invalid">
                                            SCHEDULE DEMO
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->