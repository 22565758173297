import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-service-page',
  templateUrl: './service-page.component.html',
  styleUrls: ['./service-page.component.scss']
})
export class ServicePageComponent implements OnInit {
  pageData: any = [];
  navbarOpen = false;

  constructor(private route: ActivatedRoute, public router: Router, public mainservice: MainService, private notify: NotifyService) {
    this.route.queryParams.subscribe(res => {
      if (res.page_id != "") {
        this.pageDetail(res.page_id);
        console.log('searchQuery', res.page_id);
      }
    });
  }


  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }

  pageDetail(id: any) {
    this.mainservice.pageDetails(id).subscribe((res: any) => {
      if (res['pageDetail'] != '') {
        this.pageData = res['pageDetail'];
      } else {
        this.router.navigate(['/'])
      }
    }, (error) => {
      // this.notify.showError(error.error['message'], 'Error !!');
    });
  }
  ngOnInit(): void {

  }

}
