<div class="border">
    <ul class="row-eqi-height">
        <li class="text-center">
            <span class="material-icons">
                account_circle
            </span>
            <p class="ad">Admin Account</p>
        </li>
        <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" routerLink="/dashboard/blog">
            <a>My Blogs</a>
        </li>
        <li [routerLinkActive]="'active'" routerLink="/dashboard/pages">
            <a>My Pages</a>
        </li>
        <li [routerLinkActive]="'active'" routerLink="/dashboard/subscribers">
            <a>My Subscribers</a>
        </li>
        <li [routerLinkActive]="'active'" routerLink="/dashboard/contacts">
            <a> My Contacts</a>
        </li>
        <li>
            <a (click)="logOut()">Logout</a>
        </li>
    </ul>
</div>