<section class="banner-color" *ngIf="blogDetail.banner">
    <img [src]="blogDetail.banner"  class="w-100 img-fluid h-450" alt="banner">
  </section>
  <section>
      <div class="container mt-3">
          <div class="row align-items-center no-gutters" *ngIf="blogDetail!=''">
              <div class="col-12" [innerHtml]="blogDetail.desc">
              </div>
            </div>
      </div>
  </section>
  <section>
      <app-getin-touch></app-getin-touch>
  </section>