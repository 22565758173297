<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn"> Terms and Conditions
                    </h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="main_div">
        <h1> Akhil Bhartiya Agrawal Mahasabha Terms of Use </h1>
        <p> Last updated: 14 January 2022 </p>
        <p> These Terms of Use (“Terms”) govern your use of our website located at https://abamahasabha.com made
            available
            by Akhil Bhartiya Agrawal Mahasabha a non-profit company established under the laws of India having its
            registered office at 60, First floor, Suraj Nagar, Azadpur, NS Mandi, North West Delhi, India 110033. The
            terms
            “you” and “your” refer to the user of the Platform.
        </p>
        <p>
            These Terms are to be read with the Akhil Bhartiya Agrawal Mahasabha Privacy Policy. If you do not agree
            with
            the terms and conditions of these Terms, please do not use this Platform.
        </p>
        <p>
            Our Services (as we have described below in detail) and these Terms are compliant with the Indian Penal
            Code,
            1860, and Information Technology Act, 2000, including all amendments made to it and rules framed under it.
            When
            you create an account on our Platform or use our Platform or any of our Services, you accept and agree to
            these
            Terms. However, please note that we do not represent that we are compliant with laws of any country apart
            from
            the Republic of India. If you wish to use our Services, please ensure that you are permitted to do so, in
            your
            jurisdiction.
        </p>
        <p>
            You and we are required to follow certain rules while you use our Platform. We have listed these rules in
            these
            Terms. Please read these Terms and all other hyperlinks mentioned here carefully. Do remember that by using
            our
            Platform, you agree to these Terms. Also, if you are using these services outside India, please comply with
            your
            local laws.
        </p>
        <h5> CHANGES TO TERMS AND SERVICES </h5>
        <p>
            Our Platform is dynamic and may change rapidly. As such, we may change the services we provide at our
            discretion. We may temporarily, or permanently, stop providing Services or any features to you generally.
            We may remove or add functionalities to our Platform and Services without any notice. However, if we make a
            change where your consent is required, we will make sure to ask for it. Please be sure to keep visiting this
            page from time to time to stay updated on our latest changes and developments.
            Visit this page to see any changes that we may make and services that we may add or modify, from time to
            time.
        </p>
        <h5>
            WHO MAY USE OUR SERVICES
        </h5>
        <p>
            Our Platform helps you stay in touch with our community or organisation and enables you to share images,
            videos,
            audios, other updates, and more in your preferred regional language. We understand your preferred content
            and
            personalize your newsfeed to show you posts, pictures, videos, and suggest content available on our Platform
            (“Service/Services”).
            You may use our Services only if you are capable of forming a binding agreement with us and are legally
            permitted to use our Services. If you are accepting these Terms on behalf of a company or any legal persons,
            then you represent and warrant that you have the authority to bind such entity to these Terms and
            effectively
            “you” and “your” shall refer to the company.
            Please ensure that you are allowed to use our services under the law.
        </p>

        <h5>
            HOW TO USE OUR SERVICES
        </h5>
        <p>
            We have developed a unique platform. We strive to give you belongingness to your community or your
            organisation
            via digital medium. We also allow you to download content that is available on our Platform and share your
            website experience across social media platforms. To use our Services, you must register on our Platform by
            entering your phone number and other required information. We do not read any information stored on your
            mobile
            device and computer without your permission.

        </p>
        <h5>
            PRIVACY POLICY
        </h5>
        <p>
            To effectively provide and introduce any new Services to you, we collect certain information such as your
            phone
            number and your name from you. We may further request and store additional information. Such information is
            stored securely on amazon web services or “AWS” cloud servers, other secured server or the “Google Cloud
            Platform” cloud servers, thereby also subject to the terms of the AWS or Google Cloud privacy policy. The
            Akhil
            Bhartiya Agrawal Mahasabha Privacy Policy explains how we collect, use, share and store the information
            collected. The Akhil Bhartiya Agrawal Mahasabha Privacy Policy also details your rights under law and how
            you
            may control the data you provide us.
            You provide us with some information about yourself so that we can serve you better. We have described how
            we
            store and use this information in the Akhil Bhartiya Agrawal Mahasabha Privacy Policy.
        </p>

        <h5>
            YOUR COMMITMENTS
        </h5>
        <p>
            Providing a safe Service for a broad audience requires that we all do our part. In return for our commitment
            to
            provide our Services, we require you to make some commitments to us. Your commitments to us are:

        </p>
        <p>
            1. No Impersonation or False Information to be Provided
            You are required to use your actual name on our Platform, you are required to input your correct phone
            number
            and gender to use our Services. You will not falsely represent yourself as another person or representative
            of
            another person to use our Services.
            You will not lie about your details, including your age, for any reason.

        </p>

        <p>
            2. Device Security
            We have implemented measures to ensure that our Platform is secure. However, there is no guarantee that our
            Platform is immune to virus attacks. You will ensure that you have requisite anti-malware and antivirus
            software
            on your mobile device and computer to ensure its safety. You will not allow any person to use your phone
            number,
            and not allow multiple accounts to be linked to your phone number. You will be responsible for all content
            posted by any account linked to your phone number.
            While we do everything, we can to secure your use of our Services, keep in mind that we cannot contemplate
            all
            forms of attack on our Platform. You should, as a matter of practice, ensure that your mobile device and
            computer are not used wrongly or tampered with in any way.
        </p>

        <p>
            3. Content Removal and Termination
            Your usage of our Platform is governed by the Akhil Bhartiya Agrawal Mahasabha Terms of Use. If any of our
            users
            report your content to be against the Akhil Bhartiya Agrawal Mahasabha Terms of Use, we may remove such
            content
            from our Platform. In the event that multiple reports are made regarding violation of the Akhil Bhartiya
            Agrawal
            Mahasabha Terms of Use, we may be compelled to terminate your account with us and block you from registering
            with us. If you wish to appeal any such removal, you may write to us at policy@abamahasabha.com

            We may remove any content that is shared on our Platform if such content is prohibited under the Akhil
            Bhartiya
            Agrawal Mahasabha Terms of Use.

        </p>
        <p>
            4. Platform Not to be Used for Anything Unlawful or Illegal
            Our Platform is designed to accommodate a multiplicity of languages and cultures, as well as a diverse range
            of
            contents.
            You shall not, use our Platform to share any content which is obscene, pornographic, harmful for minors,
            discriminatory, spreading hate speech, inciting any form of violence or hatred against any persons, or of
            seditious in nature, or violates any laws of the Republic of India, or is barred from being shared by any
            laws
            of the Republic of India. We reserve the right to remove such content.
            In addition to the above, please note that we may share your information with appropriate law enforcement
            authorities if we have good-faith belief that it is reasonably necessary to share your personal data or
            information in order to comply with any legal obligation; or to protect the rights or prevent any harm to
            our
            property or safety, our customers, or public; or to detect, prevent or otherwise address public safety,
            fraud,
            security or technical issues. You understand however, that we cannot be held responsible for any actions
            done by
            or to you by a third party or user by way of using our Platform.
            We have developed a platform for people to come together; please do not share any content which is illegal
            or
            causes any harm to the well-being of members of the society or community.

        </p>

        <p>
            5. Content Rights and Liabilities
            We strongly believe in the freedom of expression and allow you to share photographs, images, videos, music,
            other updates, and other content on our Platform. We do not have any ownership over any of the content
            shared by
            you and the rights in the content remain only with you. You will not use our Platform to violate or infringe
            upon our or any third-party’s intellectual property rights. Such content is against Akhil Bhartiya Agrawal
            Mahasabha Terms of Use and may be removed from the Platform. Further, if you use any content developed by
            us,
            then we shall continue to own the intellectual property rights in such content.
            By sharing/posting/uploading content using our Services, you grant us a non-exclusive, royalty-free,
            transferable, sub-licensable, worldwide license to host, use, distribute, run, copy, publicly perform or
            display, translate, and create derivative works of your content (consistent with your privacy and
            application
            settings).
            You may request to delete your content and/or account at any point. However, your content may continue to
            appear
            on the Platform if it has been shared with others. To learn more about how we use information, and how to
            control or delete your content, please read the Akhil Bhartiya Agrawal Mahasabha Privacy Policy.
            You remain solely responsible for the content you post on our Platform. We do not endorse and are not
            responsible for any content shared or posted on or through our Platform, and for the consequence of such
            sharing
            or posting. The presence of our logo or any trademark on any content shared by you does not mean that we
            have
            endorsed or sponsored your content. Further, we will not be liable for or responsible for the consequences
            of
            any transactions made or entered into by you with other users of the Platform.
            You will always have ownership and responsibilities for the content you share. We will never claim that we
            have
            intellectual property rights over your content, but will have a free of cost, permanent license to use what
            you
            share and post on our Platform.
        </p>
        <p>
            1. Intermediary Status and No Liability
            We are an intermediary under the Information Technology Act, 2000 and the Information Technology Act
            (Intermediary Guidelines) Rules, 2011. These Terms are published in accordance with the provisions of Rule
            3(1)
            of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing of the rules
            and
            regulations, Akhil Bhartiya Agrawal Mahasabha Privacy Policy, and Akhil Bhartiya Agrawal Mahasabha Terms of
            Use
            for accessing and using our Platform. Our role is limited to providing a platform to display the content
            shared
            by you and our other users.
            We do not control what people do or say and are not responsible for their (or your) actions (whether online
            or
            offline). We are not responsible for services and features offered by others, even if you access them
            through
            our Services. Our responsibility for anything that happens on our Platform is strictly governed by the laws
            of
            the Republic of India and is limited to that extent. You agree that we will not be responsible for any loss
            of
            profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or
            incidental
            damages arising out of or related to these Terms, even if we know they are possible. This includes when we
            delete your content, information, or account.
            We are an intermediary under Indian law. We do not control what people post on our Platform but we expect
            everyone to comply with the Akhil Bhartiya Agrawal Mahasabha Terms of Use.

        </p>
        <p>
            2. You Will Not Attempt to Disrupt or Jeopardize Akhil Bhartiya Agrawal Mahasabha
            We have developed a community-driven platform. Therefore, you agree to not interfere with, or use non-public
            areas of our Platform, Services, and our technical delivery system. You will not introduce any trojans,
            viruses,
            any other malicious software, any bots or scrape our Platform for any user information. Additionally, you
            will
            not probe, scan, or test the vulnerability of any system, security or authentication measures implemented by
            us.
            If you tamper or attempt to tamper with our technological design and architecture, we may terminate your
            user
            profile. We may further report such actions to the appropriate law enforcement authorities and proceed
            against
            you with legal actions.
            You will not hack into or introduce malicious software of any kind onto our Platform. If you commit such
            actions, we may remove you from the platform and even have to report your actions to the police.
            PERMISSIONS YOU GIVE TO US
            You accept these Terms and give us certain permissions so that we can serve you better. Permissions you have
            granted us are:

        </p>
        <p>
            1. Automatic Downloads and Updates
            We are constantly updating our Platform and Services offered. To use our Platform, you may need to download
            the
            Akhil Bhartiya Agrawal Mahasabha mobile application to your mobile device and update it from time to time.
            Applications and software are constantly updated for your use and you will need to install the latest
            version of
            the Akhil Bhartiya Agrawal Mahasabha mobile application to your mobile device each time such update is
            generated.

        </p>
        <p>
            2. Permission to Use Cookies
            We may use cookies, pixel tags, web beacons, mobile device IDs, flash cookies and similar files or
            technologies
            to collect and store information with respect to your use of the Services and third-party websites. Please
            see
            the Akhil Bhartiya Agrawal Mahasabha Cookie Policy for more information regarding the use of cookies and
            other
            technologies described in this section, including regarding your choices relating to such technologies.
            All websites use cookies and store them on your web browser so that usage information can be stored and
            logged
            in your browser.

        </p>

        <p>
            3. Data Retention
            We shall have the right to retain certain information regarding your usage of the Platform. Please view the
            Akhil Bhartiya Agrawal Mahasabha Privacy Policy for further information relating to the collection, storage
            and
            use of your information by us.
            You grant us the right to store and retain information relating to you and provided by you. Please see the
            Privacy Policy for further information.
            OUR AGREEMENT AND WHAT HAPPENS IF WE DISAGREE

        </p>
        <p>
            1. Who Has Rights Under These Terms
            The rights and obligations under these terms are granted only to you and shall not be assigned to any third
            party without our consent. However, we are permitted to assign our rights and obligations under these Terms
            to
            others. This can happen when, for example, we enter into a merger with another company and create a new
            company.

        </p>
        <p>
            2. How We Will Handle Disputes
            In all cases, you agree that disputes will be subject to the laws of the Republic of India and the courts of
            Delhi shall have exclusive jurisdiction over all such disputes.

        </p>
        <p>
            3. Grievance Officer
            We have a Grievance Officer to address your concerns regarding data safety, privacy, and Platform usage
            concerns. We will resolve the issues raised by you within 30 (thirty) days from receiving them. You may
            contact
            the Grievance Officer at the following Email: policy@abamahasabha.com
            We have created a method for you to get in touch with us and for us to address your concerns.

        </p>
        <p>
            LIMITATION OF LIABILITY
        </p>
        <p>
            We do not assume any liability with respect to any loss or damage, arising directly or indirectly due to any
            inaccuracy or incompleteness of any information or a breach of any warranty or guaranty due to the actions
            of
            any user of the Platform.
            The Platform and Services, are provided on "as is" and "as available" basis without any representation or
            warranties, express or implied except otherwise specified in writing. We do not warrant the quality of the
            Services or the Platform including its uninterrupted, timely, secure or error-free provision, continued
            compatibility on any device, or correction of any errors.
            In no event shall we, or any of our affiliates, successors, and assigns, and each of their respective
            investors,
            directors, officers, employees, agents, service providers, and suppliers be liable for any special,
            incidental,
            punitive, direct, indirect or consequential damages suffered as a consequence of a breach of the Terms by
            another user or arising out of the use of or the reliance on any of the Services or the Platform.
            In the event any exclusion contained herein is held to be invalid for any reason and we or any of our
            affiliate
            entities, officers, directors or employees become liable for loss or damage, then, any such liability shall
            be
            limited to not exceeding the charges or amounts paid to us for use of the Platform or the Services in the
            month
            preceding the date of the claim.

        </p>
        <p>
            INDEMNIFICATION
            You agree to indemnify, defend and hold harmless us, and our subsidiaries, affiliates and agents and their
            respective officers, directors, employees, successors and assigns from and against any claim, proceeding,
            loss,
            damage, liability, cost, demand or expense (including but not limited to attorney's fees) of any kind
            arising
            out of: (i) your access to or use of the Platform and Services; (ii) any breach by you of your obligations
            under
            this Agreement; (iii) your violation of the rights of any third party, including any infringement of
            intellectual property, or of any privacy or consumer protection right; (iv) any violation of law or
            contractual
            obligation and any claims, demands, notices pursuant to such violation; (v) your negligence or willful
            misconduct. This obligation will survive the termination of our Terms.
        </p>
        <p>
            UNSOLICITED MATERIAL
            We always appreciate feedback or other suggestions. We may use the same without any restrictions or
            obligation
            to compensate you for them and are under no obligation to keep them confidential.
            GENERAL
            1. If any aspect of these Terms is unenforceable, the rest will remain in effect.
            2. Any amendment or waiver to our Terms must be in writing and signed by us.
            3. If we fail to enforce any aspect of these Terms, including reporting any illegal or impermissible actions
            to
            appropriate law enforcement authorities or blocking or suspending your profile, such failure to enforce our
            rights will not be a waiver by us.
            4. We reserve all rights not expressly granted to you.

        </p>


    </div>
</div>