import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    SlickCarouselModule,
    RouterModule
  ],
  declarations: [

  ],
  exports: []
})
export class ShareModule { }