import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {
  blogDetail: any='';

  constructor( private route: ActivatedRoute,public router:Router,public mainservice:MainService,private notify:NotifyService) { 
    this.route.queryParams.subscribe(res => {
      if (res.blog_id != "") {
         this.blog(res.blog_id);
        console.log('searchQuery', res.blog_id);
      }
    });
  }
  blog(id:any){
    this.mainservice.blogDetail(id).subscribe((blog:any)=>{
      if(blog['success']){
        this.blogDetail =blog['blogdetail'][0];
        //  console.log("blogs",this.blogDetail);
      }else{
        this.router.navigate(['/blogs']);
        this.notify.showError(blog['message'], "Failure !!");
      }
    });
  }

  ngOnInit(): void {
  }

}
