import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchbook',
  templateUrl: './searchbook.component.html',
  styleUrls: ['./searchbook.component.scss']
})
export class SearchbookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
