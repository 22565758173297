<section style="background-color: #F8F9FC;">
    <div class="container py-5 mt-3 mt-md-5">
        <div class="row align-items-center ">
            <div class="col-12 mb-3 mb-md-5 text-center">
                <h1 class="get">Get In Touch</h1>
            </div>
            <div class="col-12">
                <form [formGroup]="getinForm" (ngSubmit)="submit()">
                    <!-- <div class="row"> -->
                    <div class="col-12 text-center">
                        <h2>Leave us a message</h2>
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Full Name</mat-label>
                                <input matInput placeholder="Placeholder" formControlName="name">
                            </mat-form-field>
                            <div Class="text-danger text-left">
                                <div *ngIf="gf.name.hasError('required') && (gf.name.dirty || gf.name.touched)">
                                    Please enter Full Name</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Email Address</mat-label>
                                <input matInput type="email" placeholder="Placeholder" formControlName="email">
                            </mat-form-field>
                            <div Class="text-danger text-left">
                                <div *ngIf="gf.email.hasError('required') && (gf.email.dirty || gf.email.touched)">
                                    Please enter email
                                </div>
                                <div *ngIf="gf.email.hasError('email') ">Only email formate allowed</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Phone Number</mat-label>
                                <input matInput placeholder="Placeholder" formControlName="mobile">
                            </mat-form-field>
                            <div Class="text-danger text-left">
                                <div *ngIf="gf.mobile.hasError('required') && (gf.mobile.dirty || gf.mobile.touched)">
                                    Please enter Mobile No.</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Your Message</mat-label>
                                <textarea formControlName="message" matInput placeholder="Placeholder"></textarea>
                            </mat-form-field>
                            <div Class="text-danger text-left">
                                <div
                                    *ngIf="gf.message.hasError('required') && (gf.message.dirty || gf.message.touched)">
                                    Please enter message</div>
                            </div>

                        </div>
                        <div class="form-group text-center mt-4">
                            <button type="submit" class="btn btn-sub" [disabled]="getinForm.invalid">
                                SEND A MESSAGE
                            </button>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 pl-3 pl-md-5">
                            <h3>
                                60, F.F.Suraj Nagar
                                Aazadpur,
                                Delhi-110033
                            </h3>
                             <h3>
                                +971 502585921
                            </h3> 
                            <h3>
                                info@abamahasabha.com
                            </h3>
                             <div class="map">
                                <div class="mapouter">
                                    <div class="gmap_canvas"><iframe class="gmap_iframe" frameborder="0" scrolling="no"
                                            marginheight="0" marginwidth="0"
                                            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=University of Oxford&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a
                                            href="#">A</a></div>
                                </div>
                            </div> 
                        </div> -->
                    <!-- </div> -->
                </form>
            </div>
        </div>
    </div>
</section>
<!-- <section>
    <div class="container">
        <div class="row align-items-center py-3 py-md-0">
            <div class="col-md-6">
                <h1>
                    Make the Shift Today
                </h1>
                <h3 class="pr-0 pr-md-3">
                    Get a personalized tour and learn how you can start accelerating your growth through sophisticated
                    omnichannel activation on the world’s first Customer Data Activation Platform (CDAP).
                </h3>
                <button class="btn btn-sub mt-3 " (click)="showModal()">
                    SCHEDULE DEMO
                </button>
            </div>
            <div class="col-md-6 text-right">
                <img src="assets/images/last_section_img.png" class="w-100 img-fluid">
            </div>
        </div>
    </div>
</section> -->



<div class="modal" id="myModal" [style.display]="viewModal ? 'block' : 'none'" style="backdrop-filter:
brightness(0.4);">
    <div class="modal-dialog modal-md" style="margin-top: 3rem;">
        <div class="modal-content p-2">
            <div class="modal-header text-right p-0 border-0">
                <button (click)="hide()" type="button" class="hide" data-dismiss="modal" aria-label="Close">
                    &times;
                </button>
            </div>
            <div class="modal-body text-center p-0">
                <div class="row mx-3">
                    <div class="col-12">
                        <form [formGroup]="demoForm" (ngSubmit)="Demosubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2>SCHEDULE YOUR DEMO</h2>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Full Name</mat-label>
                                            <input matInput placeholder="Placeholder" formControlName="name">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.name.hasError('required') && (df.name.dirty || df.name.touched)">
                                                Please enter Full Name</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email Address</mat-label>
                                            <input matInput type="email" placeholder="Placeholder"
                                                formControlName="email">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.email.hasError('required') && (df.email.dirty || df.email.touched)">
                                                Please enter email
                                            </div>
                                            <div *ngIf="df.email.hasError('email') ">Only email formate allowed</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Phone Number</mat-label>
                                            <input matInput placeholder="Placeholder" formControlName="mobile">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.mobile.hasError('required') && (df.mobile.dirty || df.mobile.touched)">
                                                Please enter Mobile No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" formControlName="service">
                                            <option disabled selected value="">Select Service</option>
                                            <option value="s">ss</option>
                                        </select>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.service.hasError('required') && (df.service.dirty || df.service.touched)">
                                                Please enter message</div>
                                        </div>

                                    </div>
                                    <div class="form-group text-center mt-4">
                                        <button type="submit" class="btn btn-sub" [disabled]="demoForm.invalid">
                                            SCHEDULE DEMO
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>