import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, share } from 'rxjs/operators';
import { NotifyService } from 'src/app/services/notify.service';



@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  user: any;
  navbarOpen = false;

  inputVal: any = '';
  showselect: boolean = false;
  news: any = [];

  private currentPage = 1;

  request: any;
  screenWidth: any;

  constructor(private notify: NotifyService, public router: Router, public dialog: MatDialog, private http: HttpClient) { }
  testislideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    // "interval":true,
    // "fade":true,
    "vertical": false,
    "autoplay": true,
    "swipeToSlide": true,
    // "draggable":true,
    "speed": 3000,
    "nextArrow": '<img class="slick-next" src="assets/images/Next.png">', // "<div class='nav-btn next-slide'></div>",
    "prevArrow": '<img class="slick-prev" src="assets/images/Pre.png">',//"<div class='nav-btn prev-slide'></div>",
    "dots": false,
    "infinite": true,
    // "focusOnSelect": true,
    // "variableWidth": true,
    // centerMode: true,
    centerPadding: '10px',
    // slidesToShow: 3,
    // "asNavFor": '.slider-nav'
    autoplaySpeed: 1000,
    //  centerMode: true,
    arrows: true
  };


  BannerslideSlider = {
    // "slidesToShow": 4,
    "slidesToScroll": 1,
    // "interval":true,
    // "fade":true,
    "vertical": false,
    "autoplay": true,
    "swipeToSlide": true,
    // "draggable":true,
    "speed": 1000,
    "nextArrow": '<img class="slick-next" src="assets/images/Next.png">', // "<div class='nav-btn next-slide'></div>",
    "prevArrow": '<img class="slick-prev" src="assets/images/Pre.png">',//"<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": true,
    // "focusOnSelect": true,
    // "variableWidth": true,
    // centerMode: true,
    centerPadding: '10px',
    // slidesToShow: 3,
    // "asNavFor": '.slider-nav'
    autoplaySpeed: 1000,
    //  centerMode: true,
    arrows: false
  };
  sponserConfig = {
    "slidesToShow": 5.6,
    "slidesToScroll": 1,
    "vertical": false,
    "autoplay": true,
    "swipeToSlide": true,
    "speed": 3000,
    "nextArrow": '<img class="slick-next" src="assets/images/Next.png">', // "<div class='nav-btn next-slide'></div>",
    "prevArrow": '<img class="slick-prev" src="assets/images/Pre.png">',//"<div class='nav-btn prev-slide'></div>",
    "dots": false,
    "infinite": true,
    centerPadding: '10px',
    autoplaySpeed: 1000,
    arrows: true
  };
  logOut() {
    sessionStorage.removeItem('user');
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }
  select(input: any) {
    this.inputVal = input;
    if (this.inputVal != '') {
      // for(let x=0; x<2; x++){
      //   this.option[x].value = this.inputVal+' '+this.option[x].old_val 
      //  }
      this.showselect = true;
    } else {
      this.inputVal = '';
      this.showselect = false;
      // for(let x=0; x<2; x++){
      //   this.option[x].value =this.option[x].old_val; 
      //  }
    }
  }

  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }
  // closeNav() {
  //   let x: any = document.getElementById("mySidepanel")
  //   if (x != null) {
  //     x.style.width = "0%";
  //   }
  // }




  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    // this.screenHeight = window.innerHeight;
    if (this.screenWidth < 600) {
      this.testislideConfig.slidesToShow = 1;
      this.sponserConfig.slidesToShow = 2;
    }
    // if (sessionStorage.getItem('user') != null) {
    //   this.user = JSON.parse(sessionStorage.getItem('user')!);
    // } else {
    //   this.user = '';
    // }
    console.log(this.user);
  }
  // this.notify.showSuccess('shiv','success');
}
