import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  statusData: any;
  p:number = 1;
  allPages: any;
  constructor(public router:Router,public mainservice:MainService,private notificationService:NotifyService) { }
  addPage(){
    this.mainservice.checkType = 2;
  }
  editPage(val:any){
    var page_id = val;
    console.log(page_id);
    sessionStorage.setItem('page_id',page_id);
    this.mainservice.checkType = 3;
  }
  status(status:any,id:any){
    console.log(status,id);
    if(status.checked){
      this.statusData = {
        'id' : id,
        'status' : 1
      }
    }else{
      this.statusData = {
        'id' : id,
        'status' : 0
      }
    }
    if(confirm("Are you sure to change status")) {
      this.mainservice.pagestatusChange(this.statusData).subscribe((res:any)=>{
        if(res['success']){
          this.notificationService.showSuccess(res['message'], "Success !!");
          //alert('success');
        }else{
          this.notificationService.showError(res['message'], "Failure !!");
        }
      });
    }

  }
  pages(){
    this.mainservice.totalPages('total').subscribe((blog:any)=>{
      this.allPages =blog['pages'];
    });
  }
  ngOnInit(): void {
    this.pages();
    sessionStorage.setItem('page_id','');
  }

}
