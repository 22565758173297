import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  allPages: any = [];
  allBusiness: any;
  allAcc: any = [];
  allCiti: any = [];
  user: any = '';

  constructor(public router: Router, public mainservice: MainService, private notificationService: NotifyService) { }
  openNav() {
    let x: any = document.getElementById("mySidepanel")
    if (x != null) {
      x.style.width = "100%";
    }
  }
  closeNav() {
    let x: any = document.getElementById("mySidepanel")
    if (x != null) {
      x.style.width = "0%";
    }
  }
  service(id: any) {
    localStorage.setItem('service_id', id);
  }
  ngOnInit(): void {
    // this.mainservice.customPages('service','business').subscribe((res:any)=>{
    //   this.allBusiness =res['pages'];
    // });
    // this.mainservice.customPages('service','accountancy').subscribe((res:any)=>{
    //   this.allAcc =res['pages'];
    // });
    // this.mainservice.customPages('program','citizenship').subscribe((res:any)=>{
    //   this.allCiti =res['pages'];
    // });
    // if (sessionStorage.getItem('user') != null) {
    //   this.user = JSON.parse(sessionStorage.getItem('user')!);
    // } else {
    //   this.user = '';
    // }
    console.log(this.user);
  }

}
