<section class="banner-color">
  <div class="container margin-horizontal py-1 py-md-5">
      <div class="row py-5">
          <div class="col-12 py-0 py-md-5">
              <h3 class="text- banner-text text-center mb-4">
                  UTILIZE EVERY CHANNEL
              </h3>
              <h2 class="text-uppercase text-white text-center font-size-64 mb-4 mb-sm-5">
                  BLOGS
              </h2>
              <p class="text-center text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br>Ut enim ad minim veniam, quis nostrud exercitation.</p>
          </div>
      </div>
  </div>
</section>
<section>
    <div class="container">
        <div class="row align-items-center no-gutters" *ngIf="allBlogs.length>0">
            <div class="col-12" *ngFor="let blog of allBlogs;">
                <div class="card my-2" routerLink="/blog/{{blog.id}}" [queryParams]="{blog_id: blog.slug}">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-sm-12 col-12">
                            <img [src]="blog.thumbnail" class="w-100 img-fluid" alt="{{blog.title}}">
                        </div>
                        <div class="col-md-8 col-sm-12 col-12 px-4 px-md-3">
                          <h2>{{blog.heading}}</h2>
                          <span style="font-size: 15px;">
                              {{blog.created_at |date}}
                          </span>
                          <p class="my-2" [innerHtml]="blog.sub_desc">
                              <!-- {{blog.desc}} -->
                          </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
    </div>
</section>
<section>
    <app-getin-touch></app-getin-touch>
</section>