import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MainLayoutComponent } from './components/layouts/main-layout/main-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { ShareModule } from './shareModule/shareModule';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxBootstrapConfirmModule } from 'ngx-bootstrap-confirm';
import { HeaderComponent } from './components/layouts/main-layout/header/header.component';
import { FooterComponent } from './components/layouts/main-layout/footer/footer.component';
import { BlogsComponent } from './components/layouts/main-layout/blogs/blogs.component';
import { BlogDetailComponent } from './components/layouts/main-layout/blog-detail/blog-detail.component';
import { AboutUsComponent } from './components/layouts/main-layout/about-us/about-us.component';
import { ContactUsComponent } from './components/layouts/main-layout/contact-us/contact-us.component';
import { NotFoundComponent } from './components/layouts/main-layout/not-found/not-found.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { AdminLoginComponent } from './components/layouts/main-layout/admin-login/admin-login.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GetinTouchComponent } from './components/getin-touch/getin-touch.component';
import { ServicePageComponent } from './components/layouts/main-layout/service-page/service-page.component';
import { RegisterFormComponent } from './components/layouts/main-layout/register-form/register-form.component';
import { LightboxModule } from 'ngx-lightbox';
import { GalleryComponent } from './components/layouts/main-layout/gallery/gallery.component';
import { AboutAgarwalComponent } from './components/layouts/main-layout/about-agarwal/about-agarwal.component';
import { DownloadCertificateComponent } from './components/layouts/main-layout/download-certificate/download-certificate.component';
import { TermComponent } from './components/layouts/main-layout/term/term.component';
import { PrivacyComponent } from './components/layouts/main-layout/privacy/privacy.component';
import { RegisterBookComponent } from './components/layouts/main-layout/register-book/register-book.component';
import { PrivacyPolicyComponent } from './components/layouts/main-layout/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './components/layouts/main-layout/refund-policy/refund-policy.component';
import { BookUserComponent } from './components/layouts/main-layout/book-user/book-user.component';
import { SearchbookComponent } from './components/layouts/main-layout/searchbook/searchbook.component';
import { MetrominialFormComponent } from './components/layouts/main-layout/metrominial-form/metrominial-form.component';
// import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,
    BlogsComponent,
    BlogDetailComponent,
    AboutUsComponent,
    ContactUsComponent,
    NotFoundComponent,
    AdminLoginComponent,
    GetinTouchComponent,
    ServicePageComponent,
    RegisterFormComponent,
    GalleryComponent,
    AboutAgarwalComponent,
    DownloadCertificateComponent,
    TermComponent,
    PrivacyComponent,
    RegisterBookComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent,
    BookUserComponent,
    SearchbookComponent,
    MetrominialFormComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ShareModule,
    SlickCarouselModule,
    MatFormFieldModule,
    MatInputModule,
    // CookieService,
    DashboardModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapConfirmModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
    }),
    MatProgressSpinnerModule,
    LightboxModule,

  ],
  providers: [

  ],
  //   entryComponents: [
  //     DialogComponent
  //  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
