<!-- Navbar & Hero Start -->
<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn"> Book Registration Form</h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Navbar & Hero End -->


<!-- <section class="banner-color">
    <div class="container margin-horizontal py-5">
        <div class="row py-5">
            <div class="col-12 py-5"> 
<h2 class="text-uppercase text-white text-center font-size-64 mb-4 mb-sm-5">
    सदस्यता फार्म
</h2>
<p class="text-center text-white">में अखिल भारतीय अग्रवाल महासभा का पेटर्न मेंबर (संरक्षक) / आजीवन सदस्य
    / तीन वर्षीय सदस्य बनना चाहता हु / चाहती हु | संस्था के नियमानुसार अपनी निजी जानकारी का विवरण ऑनलाइन
    फॉर्म के माध्यम से भर रहा हु / भर रही हु | जो की मेरे द्वारा पूर्ण रूप से सत्यापित है | </p>
</div>
</div>
</div>
</section> -->
<section style="background-color: #F8F9FC;">
    <div class="container py-5 mt-3 mt-md-5">
        <div class="row align-items-center ">
            <div class="col-12">
                <div class="wow fadeInUp" data-wow-delay="0.3s">
                    <form>
                        <script src="https://checkout.razorpay.com/v1/payment-button.js"
                            data-payment_button_id="pl_IYCZeiKqwYpDR0" async> </script>
                    </form>
                    <form [formGroup]="getinForm" (ngSubmit)="submit()">
                        <div class="row g-3">


                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" formControlName="name" class="form-control" id="name"
                                        placeholder="Full name">
                                    <label for="email">Full name <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.name.hasError('required') && (gf.name.dirty || gf.name.touched)">
                                        Please fill full name</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" placeholder="Mobile Number"
                                        formControlName="mobile" maxlength="10">
                                    <label for="email"> Mobile Number <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.mobile.hasError('required') && (gf.mobile.dirty || gf.mobile.touched)">
                                        Please fill mobile number</div>
                                    <ng-container *ngIf="gf.mobile.hasError('pattern'); else np;">
                                        <div>Only numbers allowed.</div>
                                    </ng-container>
                                    <ng-template #np>
                                        <div *ngIf="gf.mobile.hasError('minlength') ">Phone number should contains
                                            atleast 10 digits.</div>
                                        <div *ngIf=" gf.mobile.hasError('maxlength')">Phone number should not exceed
                                            10 digits.</div>
                                    </ng-template>
                                </div>

                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="email" formControlName="email" class="form-control" maxlength="100"
                                        placeholder="Email Address *">
                                    <label for="email"> Email Address <span class="text-danger">*</span></label>

                                    <div Class="text-danger text-left">
                                        <div
                                            *ngIf="gf.email.hasError('required') && (gf.email.dirty || gf.email.touched)">
                                            Please enter email address</div>
                                        <div
                                            *ngIf="gf.email.hasError('minlength') || gf.email.hasError('maxlength') || gf.email.hasError('email')">
                                            Invalid email address</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" placeholder="ईमेल आईडी"
                                        formControlName="email">
                                    <label for="email"> ईमेल आईडी</label>
                                </div>
                            </div>  -->
                            <!-- 
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="number" class="form-control" id="book_qty" placeholder="Book Quantity"
                                        formControlName="book_qty">
                                    <label for="email">Book Quantity</label>
                                </div>
                            </div> -->

                            <!-- <div Class="text-danger text-left">
                                <div
                                    *ngIf="gf.book_qty.hasError('required') && (gf.book_qty.dirty || gf.book_qty.touched)">
                                    Please fill book quantity</div>
                            </div> -->

                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="form-floating" style="position: relative;">
                                    <input type="password" formControlName="password" class="form-control "
                                        placeholder="Password *">
                                    <label for="email"> Password <span class="text-danger">*</span></label>
                                    <div Class="text-danger text-left">
                                        <div
                                            *ngIf="gf.password.hasError('required') && (gf.password.dirty || gf.password.touched)">
                                            Please enter password.</div>
                                        <div *ngIf="gf.password.hasError('minlength') ">Password number should contains
                                            atleast 6 characters.</div>
                                        <div *ngIf=" gf.password.hasError('maxlength')">Password number should not
                                            exceed 15 characters.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="form-floating" style="position: relative;">
                                    <input type="password" formControlName="confirm_password" class="form-control "
                                        placeholder="Confirm Password *">
                                    <label for="email"> Confirm Password <span class="text-danger">*</span></label>
                                    <div Class="text-danger text-left">
                                        <div
                                            *ngIf="gf.confirm_password.hasError('required') && (gf.confirm_password.dirty || gf.confirm_password.touched)">
                                            Please enter confirm password.</div>
                                        <div
                                            *ngIf=" gf.confirm_password.invalid && (gf.confirm_password.dirty || gf.confirm_password.touched)">
                                            Password and confirm password must be same.</div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-6">
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="Address" id="message"
                                        formControlName="address" style="height:100px"></textarea>
                                    <label for="message">Address <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.address.hasError('required') && (gf.address.dirty || gf.address.touched)">
                                        Please fill Address</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" placeholder="Pincode"
                                        formControlName="pincode" maxlength="6">
                                    <label for="email">Pincode <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.pincode.hasError('required') && (gf.pincode.dirty || gf.pincode.touched)">
                                        Please fill Pincode</div>
                                    <ng-container *ngIf="gf.pincode.hasError('pattern'); else pin;">
                                        <div>Only numbers allowed.</div>
                                    </ng-container>
                                    <ng-template #pin>
                                        <div *ngIf="gf.pincode.hasError('minlength') ">Pincode should contains
                                            atleast 4 digits.</div>
                                        <div *ngIf=" gf.pincode.hasError('maxlength')">Pincode should not exceed
                                            6 digits.</div>
                                    </ng-template>
                                </div>


                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <select type="text" formControlName="country_id" class="form-control"
                                        (change)="countryType($event)">
                                        <option value="">Please select country
                                        </option>
                                        <option *ngFor="let rel of this.countryArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">Country <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.country_id.hasError('required') && (gf.country_id.dirty || gf.country_id.touched)">
                                        Please select country</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="stateShow">
                                <div class="form-floating">
                                    <select type="text" formControlName="state_id" class="form-control"
                                        (change)="stateType($event)">
                                        <option value="">Please select state
                                        </option>
                                        <option *ngFor="let rel of this.stateArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">State <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.state_id.hasError('required') && (gf.state_id.dirty || gf.state_id.touched)">
                                        Please select state</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="cityShow">
                                <div class="form-floating">
                                    <select type="text" formControlName="city_id" class="form-control"
                                        (change)="cityType($event)">
                                        <option value="">Please select city
                                        </option>
                                        <option *ngFor="let rel of this.cityArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">city <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.city_id.hasError('required') && (gf.city_id.dirty || gf.city_id.touched)">
                                        Please select city</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="cityotherShow">
                                <div class="form-floating">
                                    <input type="text" formControlName="city_other" class="form-control" id="name"
                                        placeholder="Other City">
                                    <label for="email">Other City <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.city_other.hasError('required') && (gf.city_other.dirty || gf.city_other.touched)">
                                        Other City</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 text-left">
                            <div class="form-check mx-0 mx-md-2 text-left">
                                <input type="checkbox" formControlName="tnc" class="form-check-input" id="tnc">
                                <span class="pointer">
                                    I Agree to terms and conditions
                                </span>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-6">
                                <button [disabled]="getinForm.invalid" class="btn btn-primary w-100 py-3"
                                    type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="overlay text-center" *ngIf="isReady==true">
    <div class="text">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="overlay text-center" *ngIf="isState==true">
    <div class="text">
        <mat-spinner></mat-spinner>
    </div>
</div>