import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  screenWidth: any;
  navbarOpen = false;

  constructor() { }
  sponserConfig = {
    "slidesToShow": 5.6,
    "slidesToScroll": 1,
    "vertical": false,
    "autoplay": true,
    "swipeToSlide": true,
    "speed": 3000,
    "nextArrow": '<img class="slick-next" src="assets/images/Next.png">', // "<div class='nav-btn next-slide'></div>",
    "prevArrow": '<img class="slick-prev" src="assets/images/Pre.png">',//"<div class='nav-btn prev-slide'></div>",
    "dots": false,
    "infinite": true,
    centerPadding: '10px',
    autoplaySpeed: 1000,
    arrows: true
  };
  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    // this.screenHeight = window.innerHeight;
    if (this.screenWidth < 600) {
      this.sponserConfig.slidesToShow = 2;
    }
  }

  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }

}
