<!-- Navbar & Hero Start -->
<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn">कार्यकारिणी</h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Navbar & Hero End -->

<!-- Team Start -->
<div class="container-xxl py-5">
    <div class="container px-lg-5">
        <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
            <!-- <h6 class="position-relative d-inline text-primary ps-4">Our Team</h6> -->
            <h2 class="mt-2">Meet Our Team Members</h2>
        </div>
        <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                            <!-- <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                    class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                    class="fab fa-twitter"></i></a>
                            <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                    class="fab fa-instagram"></i></a>
                            <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                    class="fab fa-linkedin-in"></i></a> -->
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/1.jpeg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री सतीश अग्रवाल (मंगल)</h5>
                        <small>राष्ट्रीय अध्यक्ष </small>
                        <p><small> 7974748725 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                            <!-- <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                    class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                    class="fab fa-twitter"></i></a>
                            <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                    class="fab fa-instagram"></i></a>
                            <a class="btn btn-square text-primary bg-white my-1" href=""><i
                                    class="fab fa-linkedin-in"></i></a> -->
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/2.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0"> डॉक्टर श्रीमती अश्विनी एम अग्रवाल </h5>
                        <small>राष्ट्रीय महिला अध्यक्ष</small>
                        <p><small> 7869580481 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/3.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0"> श्री राकेश कंसल </h5>
                        <small>राष्ट्रीय उपाध्यक्ष एवं राष्ट्रीय मीडिया प्रभारी</small>
                        <p><small> 9125211821 </small></p>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/4.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0"> श्री सुशील बंसल </h5>
                        <small>राष्ट्रीय उपाध्यक्ष</small>
                        <p><small> 9899766200 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/5.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री जे के अग्रवाल</h5>
                        <small>राष्ट्रीय उपाध्यक्ष</small>
                        <p><small> 9818286507 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/6.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">डॉ लोकमणि गुप्ता (बंसल)</h5>
                        <small>राष्ट्रीय उपाध्यक्ष</small>
                        <p><small> 9351591437 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/7.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती मीरा अग्रवाल</h5>
                        <small>राष्ट्रीय महिला उपाध्यक्ष</small>
                        <p><small> 9411890823 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/8.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री लेख राज अग्रवाल</h5>
                        <small>राष्ट्रीय महामंत्री</small>
                        <p><small> 7000721500 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/9.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री बृज भूषण बंसल</h5>
                        <small>राष्ट्रीय महामंत्री</small>
                        <p><small> 7010721335 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/10.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री हरिओम गर्ग</h5>
                        <small>राष्ट्रीय महामंत्री</small>
                        <p><small> 9811602826 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/11.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री जे.के. जैन (गर्ग)</h5>
                        <small>राष्ट्रीय महामंत्री</small>
                        <p><small> 9414279707 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/12.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री राजेंद्र जमींदार (गर्ग)</h5>
                        <small>राष्ट्रीय महामंत्री</small>
                        <p><small> 9017900786 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/13.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमति निशा बंसल</h5>
                        <small>राष्ट्रीय महिला महामंत्री</small>
                        <p><small> 8400369942 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/14.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री अनूप अग्रवाल</h5>
                        <small>राष्ट्रीय कोषाध्यक्ष</small>
                        <p><small> 9827061563 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/15.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री अशोक गर्ग</h5>
                        <small>राष्ट्रीय सह कोषाध्यक्ष एवं राष्ट्रीय प्रवक्ता</small>
                        <p><small> 9229107126 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/16.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री योगेंद्र कुमार गोयल</h5>
                        <small>राष्ट्रीय मंत्री</small>
                        <p><small> 8218582156 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/17.jpeg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री दिनेश जिंदल</h5>
                        <small>राष्ट्रीय संगठन मंत्री</small>
                        <p><small> 9907231784 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/18.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री विनोद बंसल</h5>
                        <small>राष्ट्रीय संगठन मंत्री</small>
                        <p><small> 9406189700 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/19.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री नरेश अग्रवाल</h5>
                        <small>राष्ट्रीय संगठन मंत्री</small>
                        <p><small> 8518005000 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/20.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री किशोर कुमार गुप्ता</h5>
                        <small>राष्ट्रीय आईटी सेल प्रभारी</small>
                        <p><small> 9321203369 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/21.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री विनोद कुमार खदरिया( गोयल)</h5>
                        <small>शिकायत निराकरण अधिकारी</small>
                        <p><small> 9414481412 </small></p>
                    </div>
                </div>
            </div>



            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/22.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री भानु प्रकाश बंसल</h5>
                        <small>बुक बैंक संयोजक</small>
                        <p><small> 9414256262 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/23.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री नवनीत गर्ग</h5>
                        <small>प्रदेश अध्यक्ष उत्तराखंड</small>
                        <p><small> 7017859251 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/24.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री राज कुमार अग्रवाल</h5>
                        <small>प्रदेश अध्यक्ष छत्तीसगढ़</small>
                        <p><small> 8819060000 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/25.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री महेंद्र कुमार धानुका (बंसल)</h5>
                        <small>प्रदेश अध्यक्ष गुजरात</small>
                        <p><small> 9062920803 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/26.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री सुरेश रामपिपलिया अग्रवाल </h5>
                        <small>प्रदेश अध्यक्ष मध्य प्रदेश</small>
                        <p><small> 9826297754 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/27.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री सचिन मित्तल </h5>
                        <small>प्रदेश अध्यक्ष हरियाणा</small>
                        <p><small> 9896060776 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/28.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री जुगल किशोर अग्रवाल (मंगल)</h5>
                        <small>प्रदेश अध्यक्ष राजस्थान</small>
                        <p><small> 9829115355 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/29.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री सज्जन अग्रवाल</h5>
                        <small>प्रदेश अध्यक्ष महाराष्ट्र</small>
                        <p><small> 9322281706 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/30.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री श्रवण कुमार अग्रवाल</h5>
                        <small>प्रदेश अध्यक्ष उड़ीसा</small>
                        <p><small> 9938614435 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/31.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री निरंजन लाल अग्रवाल</h5>
                        <small>प्रदेश अध्यक्ष उत्तर प्रदेश</small>
                        <p><small> 9453020009 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/32.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती शशि गर्ग</h5>
                        <small>प्रदेश महिला अध्यक्ष हरियाणा</small>
                        <p><small> 9317501551 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/33.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती संजू अग्रवाल</h5>
                        <small>प्रदेश महिला अध्यक्ष गुजरात</small>
                        <p><small> 6352044991 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/34.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती दुर्गेश अग्रवाल</h5>
                        <small>प्रदेश महिला अध्यक्ष उत्तर प्रदेश</small>
                        <p><small> 7017752680 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/35.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती कंचन अग्रवाल</h5>
                        <small>प्रदेश महिला अध्यक्ष उड़ीसा</small>
                        <p><small> 9040210942 </small></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/36.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती मधु जिंदल</h5>
                        <small>प्रदेश महिला अध्यक्ष तमिल नाडु</small>
                        <p><small> 8428572711 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/37.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती संध्या केडिया (गर्ग)</h5>
                        <small>प्रदेश महिला अध्यक्ष छत्तीसगढ़</small>
                        <p><small> 8319118940 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/38.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती अर्चना गुप्ता</h5>
                        <small>प्रदेश महिला अध्यक्ष दिल्ली</small>
                        <p><small> 8588834620 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/39.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती पिंकी सिंघल</h5>
                        <small>प्रदेश महिला अध्यक्ष मध्य प्रदेश</small>
                        <p><small> 9098370996 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/40.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती प्रीति अमित अग्रवाल</h5>
                        <small>प्रदेश महिला अध्यक्ष राजस्थान</small>
                        <p><small> 7426081343 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/41.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती सीमा अशोक जी पंच अग्रवाल</h5>
                        <small>प्रदेश महिलाअध्यक्ष महाराष्ट्र</small>
                        <p><small> 8999314721 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/42.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्रीमती मोरना गोयल</h5>
                        <small>प्रदेश महिला अध्यक्ष पंजाब</small>
                        <p><small> 9988221187 </small></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-item">
                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                        </div>
                        <img class="img-fluid rounded w-50" src="assets/images/attachments/43.jpg" alt="">
                    </div>
                    <div class="px-4 py-3">
                        <h5 class="fw-bold m-0">श्री मोहित बंसल</h5>
                        <small>युवा प्रदेश अध्यक्ष उत्तराखंड</small>
                        <p><small> 9837363338 </small></p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Team End -->