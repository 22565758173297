import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-addblog',
  templateUrl: './addblog.component.html',
  styleUrls: ['./addblog.component.scss']
})
export class AddblogComponent implements OnInit {
  AddBlog: FormGroup;
  fileToUpload: any;
  banner: any;
  thumbnail: any;
  constructor(public router:Router,formBuilder : FormBuilder,public mainservice:MainService,private notificationService:NotifyService) {
    this.AddBlog=formBuilder.group({
      title:new FormControl('', Validators.required),
      description:new FormControl('', Validators.required),
      sub_description:new FormControl('', Validators.required),
      banner:new FormControl(''),
      thumbnail:new FormControl(''),
      heading:new FormControl('', Validators.required),
      sub_heading:new FormControl('', Validators.required),
      seo_url:new FormControl(''),
      sort:new FormControl(''),
      meta_title:new FormControl(''),
      meta_keyword:new FormControl(''),
      meta_desc:new FormControl(''),
    });
  }
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
};
  get bf() { return this.AddBlog['controls'] }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        // const max_size = 20971520;
        // const allowed_types = ['image/png', 'image/jpeg'];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.banner  = e.target.result;
        };
        reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  thumbnailFileInput(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      // const max_size = 20971520;
      // const allowed_types = ['image/png', 'image/jpeg'];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.thumbnail  = e.target.result;
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  submit(){
    let submitdata = {
      'title' : this.bf.title.value,
      'desc': this.bf.description.value,
      'sub_desc' : this.bf.sub_description.value,
      'banner' : (this.banner) ? this.banner : '',
      'thumbnail' :(this.thumbnail)? this.thumbnail:'',
      'heading' : this.bf.heading.value,
      'sub_heading' : this.bf.sub_heading.value,
      'sort' : this.bf.sort.value,
      'slug' : this.bf.seo_url.value,
      'meta_title' : this.bf.meta_title.value,
      'meta_keyword' : this.bf.meta_keyword.value,
      'meta_desc' : this.bf.meta_desc.value,
    }
    console.log("this",submitdata);
    // this.mainservice.addBlog(submitdata).subscribe((res:any)=>{
    //   if(res['success']){
    //     this.AddBlog.reset();
    //     this.notificationService.showSuccess(res['message'], "Success !!");
    //     //alert('success');
    //   }else{
    //     this.notificationService.showError(res['message'], "Failure !!");
    //   }
    // });
  }
  back(){
    this.mainservice.checkType = 1;
  }
  ngOnInit(): void {
  }
}
