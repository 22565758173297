import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-agarwal',
  templateUrl: './about-agarwal.component.html',
  styleUrls: ['./about-agarwal.component.scss']
})
export class AboutAgarwalComponent implements OnInit {

  navbarOpen = false;

  constructor() { }

  ngOnInit(): void {
  }

  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }

}
