<section>
    <div class="row no-gutters">
        <div class="col-2 p-0 web" style="background: #0e2e7b;">
            <app-sidenav></app-sidenav>
        </div>
        <div class="col-md-10 col-sm-12 px-3">
          <div class="row no-gutters py-3">
            <div class="col-12">
                <div class="card border-0">
                    <div class="col-sm-12">
                        <h2 class="card-title mb-0">All Subscribers</h2>
                    </div> 
                  <!-- <hr> -->
                <div class="card-body content-height">
                  <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col"><p><b>No</b></p></th>
                        <th scope="col"><p><b>Id</b></p></th>
                        <th scope="col"><p><b>Email</b></p></th> 
                        <th scope="col"><p><b>Date</b></p></th>               
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor=" let sub of allSubscribers; let i=index;">
                        <td>{{(4*(p-1))+(i+1)}}</td>            
                        <td>{{sub.id}}</td>
                        <td>{{sub.email}}</td>
                        <td>{{sub.created_at}}</td>
                        </tr>            
                    </tbody>
                  </table> 
                </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</section>