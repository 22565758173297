<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn"> डाउनलोड प्रमाण पत्र

                    </h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>

<section style="background-color: #F8F9FC;">
    <div class="container py-5 mt-3 mt-md-5">
        <div class="row align-items-center ">
            <div class="col-12">
                <div class="wow fadeInUp" data-wow-delay="0.3s">
                    <form [formGroup]="getinForm" (ngSubmit)="submit()">
                        <div class="row g-3">

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" placeholder=" मोबाइल नंबर"
                                        formControlName="mobile">
                                    <label for="email"> मोबाइल नंबर</label>
                                </div>

                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.mobile.hasError('required') && (gf.mobile.dirty || gf.mobile.touched)">
                                        कृपया मोबाइल नंबर भरें</div>
                                    <ng-container *ngIf="gf.mobile.hasError('pattern'); else np;">
                                        <div>Only numbers allowed.</div>
                                    </ng-container>
                                    <ng-template #np>
                                        <div *ngIf="gf.mobile.hasError('minlength') ">Phone number should contains
                                            atleast 7 digits.</div>
                                        <div *ngIf=" gf.mobile.hasError('maxlength')">Phone number should not exceed
                                            15 digits.</div>
                                    </ng-template>
                                </div>

                            </div>

                            <div class="col-6">
                                <div class="form-floating">
                                    <input type="date" class="form-control" formControlName="dob" />
                                    <label for="subject">जन्मतिथि</label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.dob.hasError('required') && (gf.dob.dirty || gf.dob.touched)">
                                        कृपया जन्मतिथि भरें</div>
                                </div>
                            </div>

                            <div class="col-6">
                                <button class="btn btn-primary w-100 py-3" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="overlay text-center" *ngIf="isReady==true">
    <div class="text">
        <mat-spinner></mat-spinner>
    </div>
</div>