<!-- Navbar & Hero Start -->
<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn"> वैवाहिक फार्म</h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Navbar & Hero End -->


<!-- <section class="banner-color">
    <div class="container margin-horizontal py-5">
        <div class="row py-5">
            <div class="col-12 py-5"> 
<h2 class="text-uppercase text-white text-center font-size-64 mb-4 mb-sm-5">
    सदस्यता फार्म
</h2>
<p class="text-center text-white">में अखिल भारतीय अग्रवाल महासभा का पेटर्न मेंबर (संरक्षक) / आजीवन सदस्य
    / तीन वर्षीय सदस्य बनना चाहता हु / चाहती हु | संस्था के नियमानुसार अपनी निजी जानकारी का विवरण ऑनलाइन
    फॉर्म के माध्यम से भर रहा हु / भर रही हु | जो की मेरे द्वारा पूर्ण रूप से सत्यापित है | </p>
</div>
</div>
</div>
</section> -->
<section style="background-color: #F8F9FC;">
    <div class="container py-5 mt-3 mt-md-5">
        <div class="row align-items-center ">
            <div class="col-12">
                <div class="wow fadeInUp" data-wow-delay="0.3s">
                    <!-- <form>
                        <script src="https://checkout.razorpay.com/v1/payment-button.js"
                            data-payment_button_id="pl_IYCZeiKqwYpDR0" async> </script>
                    </form> -->
                    <form [formGroup]="getinForm" (ngSubmit)="submit()">
                        <div class="row g-3">
                            <div class="col-md-12">
                                <div class="form-floating">
                                    <input type="text" formControlName="name" class="form-control" id="name"
                                        placeholder="Name Of Candidate">
                                    <label for="email">Name Of Candidate <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.name.hasError('required') && (gf.name.dirty || gf.name.touched)">
                                        Please fill name of candidate</div>
                                </div>
                            </div>


                            <div class="col-4">
                                <div class="form-floating">
                                    <input type="date" class="form-control" formControlName="dob" />
                                    <!-- <input type="text" class="form-control" id="subject" placeholder="Subject"> -->
                                    <label for="subject">Date Of Birth <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.dob.hasError('required') && (gf.dob.dirty || gf.dob.touched)">
                                        Please fill date of birth</div>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" placeholder="Mobile Number"
                                        formControlName="mobile" maxlength="10">
                                    <label for="email"> Mobile Number <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.mobile.hasError('required') && (gf.mobile.dirty || gf.mobile.touched)">
                                        Please fill mobile number</div>
                                    <ng-container *ngIf="gf.mobile.hasError('pattern'); else np;">
                                        <div>Only numbers allowed.</div>
                                    </ng-container>
                                    <ng-template #np>
                                        <div *ngIf="gf.mobile.hasError('minlength') ">Phone number should contains
                                            atleast 10 digits.</div>
                                        <div *ngIf=" gf.mobile.hasError('maxlength')">Phone number should not exceed
                                            10 digits.</div>
                                    </ng-template>
                                </div>

                            </div>



                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="gotra" class="form-control">
                                        <option value="">Select Gotra</option>
                                        <option value="garg">गर्ग &nbsp;(Garg or Gargeya)</option>
                                        <option value="goyal">गोयल (Goyal, Goel or Goenka)</option>
                                        <option value="Jindal">जिंदल (Jindal)</option>
                                        <option value="Bindal">बिंदल (Bindal or Vindal)</option>
                                        <option value="Kandal">कंदल &nbsp;(Kandal)</option>
                                        <option value="Bansal">बंसल (Bansal)</option>
                                        <option value="Jinjal">जिंजल (Jinjal)</option>
                                        <option value="Binchhal">बिंछल (Binchhal)</option>
                                        <option value="Kansal">कांसल (Kansal)</option>
                                        <option value="Bhedal">भेदल &nbsp;(Bhedal / Bhandal)</option>
                                        <option value="Mangal">मंगल (Mangal)</option>
                                        <option value="Mittal">मित्तल (Mittal)</option>

                                        <option value="Singal">सिंगल (Singal, Singhal)</option>
                                        <option value="Airan">एरण (Airan/Aeron)</option>
                                        <option value="Nangal">नांगल (Nangal)</option>
                                        <option value="Kuchhal">कुंच्छल (Kuchhal / Kuchchal)</option>
                                        <option value="Tayal">तायल (Tayal)</option>
                                    </select>
                                    <label for="email">Gotra <span class="text-danger">*</span> </label>
                                </div>

                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.gotra.hasError('required') && (gf.gotra.dirty || gf.gotra.touched)">
                                        Please select gotra</div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="height" class="form-control">
                                        <option value="">Select Height</option>
                                        <option value="4 feet">4 feet</option>
                                        <option value="4 feet 1 Inches">4 feet 1 Inches</option>
                                        <option value="4 feet 2 Inches">4 feet 2 Inches</option>
                                        <option value="4 feet 3 Inches">4 feet 3 Inches</option>
                                        <option value="4 feet 4 Inches">4 feet 4 Inches</option>
                                        <option value="4 feet 5 Inches">4 feet 5 Inches</option>
                                        <option value="4 feet 6 Inches">4 feet 6 Inches</option>
                                        <option value="4 feet 7 Inches">4 feet 7 Inches</option>
                                        <option value="4 feet 8 Inches">4 feet 8 Inches</option>
                                        <option value="4 feet 9 Inches">4 feet 9 Inches</option>
                                        <option value="4 feet 10 Inches">4 feet 10 Inches</option>
                                        <option value="4 feet 11 Inches">4 feet 11 Inches</option>
                                        <option value="5 feet">5 feet</option>
                                        <option value="5 feet 1 Inches">5 feet 1 Inches</option>
                                        <option value="5 feet 2 Inches">5 feet 2 Inches</option>
                                        <option value="5 feet 3 Inches">5 feet 3 Inches</option>
                                        <option value="5 feet 4 Inches">5 feet 4 Inches</option>
                                        <option value="5 feet 5 Inches">5 feet 5 Inches</option>
                                        <option value="5 feet 6 Inches">5 feet 6 Inches</option>
                                        <option value="5 feet 7 Inches">5 feet 7 Inches</option>
                                        <option value="5 feet 8 Inches">5 feet 8 Inches</option>
                                        <option value="5 feet 9 Inches">5 feet 9 Inches</option>
                                        <option value="5 feet 10 Inches">5 feet 10 Inches</option>
                                        <option value="5 feet 11 Inches">5 feet 11 Inches</option>
                                        <option value="6 feet">6 feet</option>
                                        <option value="6 feet 1 Inches">6 feet 1 Inches</option>
                                        <option value="6 feet 2 Inches">6 feet 2 Inches</option>
                                        <option value="6 feet 3 Inches">6 feet 3 Inches</option>
                                        <option value="6 feet 4 Inches">6 feet 4 Inches</option>
                                        <option value="6 feet 5 Inches">6 feet 5 Inches</option>
                                        <option value="6 feet 6 Inches">6 feet 6 Inches</option>
                                        <option value="6 feet 7 Inches">6 feet 7 Inches</option>
                                        <option value="6 feet 8 Inches">6 feet 8 Inches</option>
                                        <option value="6 feet 9 Inches">6 feet 9 Inches</option>
                                        <option value="6 feet 10 Inches">6 feet 10 Inches</option>
                                        <option value="6 feet 11 Inches">6 feet 11 Inches</option>
                                    </select>
                                    <label for="email">Height <span class="text-danger">*</span> </label>
                                </div>

                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.height.hasError('required') && (gf.height.dirty || gf.height.touched)">
                                        Please select height</div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" formControlName="weight" class="form-control" id="weight"
                                        placeholder="Weight (In KG)">
                                    <label for="email">Weight (In KG) <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.weight.hasError('required') && (gf.weight.dirty || gf.weight.touched)">
                                        Please fill weight</div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="color" class="form-control">
                                        <option value="">Color
                                        </option>
                                        <option value="sanwala">Sanwala</option>
                                        <option value="gora">Gora</option>
                                        <option value="gehua">Gehua</option>
                                    </select>
                                    <label for="name">Color <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.color.hasError('required') && (gf.color.dirty || gf.color.touched)">
                                        Please select color</div>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" formControlName="education" class="form-control" id="education"
                                        placeholder="Education">
                                    <label for="email">Education <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.education.hasError('required') && (gf.education.dirty || gf.education.touched)">
                                        Please fill education</div>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="business" class="form-control">
                                        <option value="">Select Business
                                        </option>
                                        <option value="Nil">Nil</option>
                                        <option value="Own Business">Own Business</option>
                                        <option value="Private Service">Private Service</option>
                                        <option value="State Govt. Service">State Govt. Service</option>
                                        <option value="Central Govt. Service">Central Govt. Service</option>
                                    </select>
                                    <label for="name">Business/Service <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.business.hasError('required') && (gf.business.dirty || gf.business.touched)">
                                        Please select Business/Service</div>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" formControlName="monthly_income" class="form-control"
                                        id="monthly_income" placeholder="Monthly Income">
                                    <label for="email">Monthly Income (INR)<span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.monthly_income.hasError('required') && (gf.monthly_income.dirty || gf.monthly_income.touched)">
                                        Please fill monthly income</div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="house" class="form-control">
                                        <option value="">Select House
                                        </option>
                                        <option value="Own">Own</option>
                                        <option value="Rented">Rented</option>
                                    </select>
                                    <label for="name">House <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.house.hasError('required') && (gf.house.dirty || gf.house.touched)">
                                        Please select House</div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="mangalik" class="form-control">
                                        <option value="">Select Mangalik
                                        </option>
                                        <option value="mangalik">Mangalik</option>
                                        <option value="Non mangalik">Non mangalik</option>
                                        <option value="Anshik mangalik">Anshik mangalik</option>
                                    </select>
                                    <label for="name">Mangalik <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.mangalik.hasError('required') && (gf.mangalik.dirty || gf.mangalik.touched)">
                                        Please select Mangalik</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="special_status" class="form-control">
                                        <option value="">Select Special Status
                                        </option>
                                        <option value="widower">Widower</option>
                                        <option value="widow">Widow</option>
                                        <option value="divorce">Divorce</option>
                                        <option value="disable">Disable</option>
                                        <option value="NA">Not Applicable</option>
                                    </select>
                                    <label for="name">Special Status <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.special_status.hasError('required') && (gf.special_status.dirty || gf.special_status.touched)">
                                        Please select special status</div>
                                </div> 
                            </div>



                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="brother_married" id="name"
                                        placeholder="Brother Married">
                                    <label for="email">Brother Married <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.brother_married.hasError('required') && (gf.brother_married.dirty || gf.brother_married.touched)">
                                        Please fill Brother Married
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="brother_unmarried"
                                        id="name" placeholder="Brother UnMarried">
                                    <label for="email">Brother UnMarried <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.brother_unmarried.hasError('required') && (gf.brother_unmarried.dirty || gf.brother_unmarried.touched)">
                                        Please fill Brother UnMarried
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="father" class="form-control">
                                        <option value="">Select Father Status
                                        </option>
                                        <option value="live">Live</option>
                                        <option value="passed away">Passed Away</option>
                                    </select>
                                    <label for="name">Father Status<span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.father.hasError('required') && (gf.father.dirty || gf.father.touched)">
                                        Please select Father Status</div>
                                </div>
                            </div>



                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="sister_married" id="name"
                                        placeholder="Sister Married">
                                    <label for="email">Sister Married <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.sister_married.hasError('required') && (gf.sister_married.dirty || gf.sister_married.touched)">
                                        Please fill Sister Married
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="sister_unmarried" id="name"
                                        placeholder="Sister UnMarried">
                                    <label for="email">Sister UnMarried <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.sister_unmarried.hasError('required') && (gf.sister_unmarried.dirty || gf.sister_unmarried.touched)">
                                        Please fill Sister UnMarried
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="mother" class="form-control">
                                        <option value="">Select Mother Status
                                        </option>
                                        <option value="live">Live</option>
                                        <option value="passed away">Passed Away</option>
                                    </select>
                                    <label for="name">Mother status<span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.mother.hasError('required') && (gf.mother.dirty || gf.mother.touched)">
                                        Please select mother status</div>
                                </div>
                            </div>




                            <div class="col-md-12">
                                <div class="form-floating">
                                    <input type="text" formControlName="fname" class="form-control" id="fname"
                                        placeholder="father name Of Candidate">
                                    <label for="email">Father name Of Candidate <span
                                            class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div *ngIf="gf.fname.hasError('required') && (gf.fname.dirty || gf.fname.touched)">
                                        Please fill Father name of candidate</div>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-floating">
                                    <select type="text" formControlName="father_business" class="form-control">
                                        <option value="">Select Business
                                        </option>
                                        <option value="Nil">Nil</option>
                                        <option value="Own Business">Own Business</option>
                                        <option value="Private Service">Private Service</option>
                                        <option value="State Govt. Service">State Govt. Service</option>
                                        <option value="Central Govt. Service">Central Govt. Service</option>
                                    </select>
                                    <label for="name">Business/Service <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.father_business.hasError('required') && (gf.father_business.dirty || gf.father_business.touched)">
                                        Please select Business/Service</div>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" formControlName="father_monthly_income" class="form-control"
                                        id="father_monthly_income" placeholder="Monthly Income">
                                    <label for="email">Monthly Income (INR)<span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.father_monthly_income.hasError('required') && (gf.father_monthly_income.dirty || gf.father_monthly_income.touched)">
                                        Please fill monthly income</div>
                                </div>
                            </div>


                            <div class="col-md-4">
                                <div class="form-floating">
                                    <input type="text" formControlName="father_other" class="form-control"
                                        id="father_other" placeholder="Other">
                                    <label for="email">Other<span class="text-danger">*</span></label>
                                </div>
                            </div>

                        
                        <div class="col-6">
                            <div class="form-floating">
                                <textarea class="form-control" placeholder="Remark" id="message"
                                    formControlName="remark" style="height:100px"></textarea>
                                <label for="message">Remark <span class="text-danger">*</span></label>
                            </div>
                            <div Class="text-danger text-left">
                                <div *ngIf="gf.remark.hasError('required') && (gf.remark.dirty || gf.remark.touched)">
                                    Please fill remark</div>
                            </div>
                        </div>

                            <div class="col-6">
                                <div class="form-floating">
                                    <textarea class="form-control" placeholder="Address" id="message"
                                        formControlName="address" style="height:100px"></textarea>
                                    <label for="message">Address <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.address.hasError('required') && (gf.address.dirty || gf.address.touched)">
                                        Please fill address</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="file" class="form-control" (change)="onFileChange($event, 'profile')">
                                    <label for="email">Photo</label>
                                </div>
                                <!-- <div Class="text-danger text-left">
                                    <div *ngIf="gf.profile.hasError('required') && (gf.profile.dirty || gf.profile.touched)">
                                        कृपया फोटो (खुद की पासपोर्ट साइज) चुने</div>
                                </div> -->
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <select type="text" formControlName="marriage_type" class="form-control"
                                        (change)="marriageType()">
                                        <option value="">Select Type
                                        </option>
                                        <option value="1">निःशुल्क सदस्यता पंजीकरण</option>
                                        <option value="2">विवाह पंजीकरण शुल्क</option>
                                    </select>
                                    <label for="name">Registration Mode <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.payment_mode.hasError('required') && (gf.payment_mode.dirty || gf.payment_mode.touched)">
                                        Please select Type</div>
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="ismarriaedShow">
                                <div class="form-floating">
                                    <select type="text" formControlName="payment_mode" class="form-control"
                                        (change)="indType()">
                                        <option value="">Select payment mode
                                        </option>
                                        <option value="1">Online Mode</option>
                                        <option value="2">Offline Mode</option>
                                    </select>
                                    <label for="name">Payment Mode <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.payment_mode.hasError('required') && (gf.payment_mode.dirty || gf.payment_mode.touched)">
                                        Please select payment mode</div>
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="isindevidualShow">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="payment_type" id="name"
                                        placeholder="cash/cheque/DD">
                                    <label for="email"> cash/cheque/DD </label>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="name" placeholder="Pincode"
                                        formControlName="pincode" maxlength="6">
                                    <label for="email">Pincode <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.pincode.hasError('required') && (gf.pincode.dirty || gf.pincode.touched)">
                                        Please fill Pincode</div>
                                    <ng-container *ngIf="gf.pincode.hasError('pattern'); else pin;">
                                        <div>Only numbers allowed.</div>
                                    </ng-container>
                                    <ng-template #pin>
                                        <div *ngIf="gf.pincode.hasError('minlength') ">Pincode should contains
                                            atleast 4 digits.</div>
                                        <div *ngIf=" gf.pincode.hasError('maxlength')">Pincode should not exceed
                                            6 digits.</div>
                                    </ng-template>
                                </div>


                            </div>

                            <div class="col-md-6">
                                <div class="form-floating">
                                    <select type="text" formControlName="country_id" class="form-control"
                                        (change)="countryType($event)">
                                        <option value="">Select Country
                                        </option>
                                        <option *ngFor="let rel of this.countryArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">Country <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.country_id.hasError('required') && (gf.country_id.dirty || gf.country_id.touched)">
                                        Please select country</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="stateShow">
                                <div class="form-floating">
                                    <select type="text" formControlName="state_id" class="form-control"
                                        (change)="stateType($event)">
                                        <option value="">Select state
                                        </option>
                                        <option *ngFor="let rel of this.stateArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">State <span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.state_id.hasError('required') && (gf.state_id.dirty || gf.state_id.touched)">
                                        Please select state</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="cityShow">
                                <div class="form-floating">
                                    <select type="text" formControlName="city_id" class="form-control"
                                        (change)="cityType($event)">
                                        <option value="">Select City
                                        </option>
                                        <option *ngFor="let rel of this.cityArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <label for="name">City<span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.city_id.hasError('required') && (gf.city_id.dirty || gf.city_id.touched)">
                                        Please select city</div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="cityotherShow">
                                <div class="form-floating">
                                    <input type="text" formControlName="city_other" class="form-control" id="name"
                                        placeholder="Other City">
                                    <label for="email">Other City<span class="text-danger">*</span></label>
                                </div>
                                <div Class="text-danger text-left">
                                    <div
                                        *ngIf="gf.city_other.hasError('required') && (gf.city_other.dirty || gf.city_other.touched)">
                                        Other City</div>
                                </div>
                            </div>


                            <!-- <div class="col-12" *ngIf="isindevidualShow">
                                <div class="form-group"
                                    [ngClass]="{ 'was-validated': sf.vendorType.valid &&  sf.vendorType.touched}">
                                    <select type="text" formControlName="vendorType" class="form-control"
                                        [ngClass]="{ 'is-invalid': sf.vendorType.invalid && (sf.vendorType.dirty || sf.vendorType.touched) }"
                                        required>
                                        <option value="">Select Vendor Type</option>
                                        <option *ngFor="let rel of this.vendorArr; let i = index;" [value]="rel.id">
                                            {{rel.name}}
                                        </option>
                                    </select>
                                    <div Class="text-danger text-left">
                                        <div
                                            *ngIf="sf.vendorType.invalid && (sf.vendorType.dirty || sf.vendorType.touched)">
                                            Please select Vendor Type.</div>
                                    </div>
                                </div>
                            </div>
                             -->

                        </div>
                         

                        <!-- <div class="col-12 text-left">
                            <div class="form-check mx-0 mx-md-2 text-left">
                                <input type="checkbox" formControlName="tnc" class="form-check-input" id="tnc">
                                <span class="pointer">
                                    I Agree to terms and conditions
                                </span>
                            </div>
                        </div> -->

                        <div class="row mt-3">
                            <div class="col-6">
                                <button [disabled]="getinForm.invalid" class="btn btn-primary w-100 py-3"
                                    type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="overlay text-center" *ngIf="isReady==true">
    <div class="text">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="overlay text-center" *ngIf="isState==true">
    <div class="text">
        <mat-spinner></mat-spinner>
    </div>
</div>

<!-- <section>
    <div class="container">
        <div class="row align-items-center py-3 py-md-0">
            <div class="col-md-6">
                <h1>
                    Make the Shift Today
                </h1>
                <h3 class="pr-0 pr-md-3">
                    Get a personalized tour and learn how you can start accelerating your growth through sophisticated
                    omnichannel activation on the world’s first Customer Data Activation Platform (CDAP).
                </h3>
                <button class="btn btn-sub mt-3 " (click)="showModal()">
                    SCHEDULE DEMO
                </button>
            </div>
            <div class="col-md-6 text-right">
                <img src="assets/images/last_section_img.png" class="w-100 img-fluid">
            </div>
        </div>
    </div>
</section> -->



<!-- <div class="modal" id="myModal" [style.display]="viewModal ? 'block' : 'none'" style="backdrop-filter:
brightness(0.4);">
    <div class="modal-dialog modal-md" style="margin-top: 3rem;">
        <div class="modal-content p-2">
            <div class="modal-header text-right p-0 border-0">
                <button (click)="hide()" type="button" class="hide" data-dismiss="modal" aria-label="Close">
                    &times;
                </button>
            </div>
            <div class="modal-body text-center p-0">
                <div class="row mx-3">
                    <div class="col-12">
                        <form [formGroup]="demoForm" (ngSubmit)="Demosubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2>SCHEDULE YOUR DEMO</h2>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Full Name</mat-label>
                                            <input matInput placeholder="Placeholder" formControlName="name">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.name.hasError('required') && (df.name.dirty || df.name.touched)">
                                                Please enter Full Name</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email Address</mat-label>
                                            <input matInput type="email" placeholder="Placeholder"
                                                formControlName="email">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.email.hasError('required') && (df.email.dirty || df.email.touched)">
                                                Please enter email
                                            </div>
                                            <div *ngIf="df.email.hasError('email') ">Only email formate allowed</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Phone Number</mat-label>
                                            <input matInput placeholder="Placeholder" formControlName="mobile">
                                        </mat-form-field>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.mobile.hasError('required') && (df.mobile.dirty || df.mobile.touched)">
                                                Please enter Mobile No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" formControlName="service">
                                            <option disabled selected value="">Select Service</option>
                                            <option value="s">ss</option>
                                        </select>
                                        <div Class="text-danger text-left">
                                            <div
                                                *ngIf="df.service.hasError('required') && (df.service.dirty || df.service.touched)">
                                                Please enter message</div>
                                        </div>

                                    </div>
                                    <div class="form-group text-center mt-4">
                                        <button type="submit" class="btn btn-sub" [disabled]="demoForm.invalid">
                                            SCHEDULE DEMO
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->