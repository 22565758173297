<!-- <section>
    <div class="row no-gutters">
        <div class="col-2 p-0 web" style="background: #0e2e7b;">
            <app-sidenav></app-sidenav>
        </div>
        <div class="col-md-10 col-sm-12 px-3"> -->
            <div class="row align-items-center no-gutters px-3 py-1">
                <div class="col-6">
                    <h2 class="mb-0">Edit Page</h2>
                </div>
                <div class="col-6 text-right">
                    <button class="btn-add btn" (click)="back()">Back  
                        <span class="material-icons">
                            undo
                        </span>
                    </button>
                </div>
                </div>
                <div class="row justify-content-center my-3">
                <div class="col-md-12 col-sm-12 px-5">
                    <form name="EditPage" [formGroup]="EditPage" (ngSubmit)="submit()" autocomplete="off">
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="name"> Page Title: </label>
                            <input type="text" formControlName="page_title" class="form-control" id="title"  placeholder="Enter Page Title">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="Name"> Page Name: </label>
                            <input type="text" formControlName="page_name" class="form-control" id="Name"  placeholder="Enter Page Name">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="url">URL</label>
                            <input type="text" formControlName="seo_url" class="form-control" id="seo_url"  placeholder="Enter Page Url">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="Meta Title"> Meta Title: </label>
                            <input type="text" formControlName="meta_title" class="form-control" id="meta_title"  placeholder="Meta Title">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="subheaMeta Keywordding"> Meta Keyword: </label>
                            <input type="text" formControlName="meta_keyword" class="form-control" id="meta_keyword"  placeholder="Meta Keyword">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="subheaMeta "> Meta Description: </label>
                            <input type="text" formControlName="meta_desc" class="form-control" id="meta_desc"  placeholder="Meta Description">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="Sort">Sort No</label>
                            <input type="text" formControlName="sort" class="form-control" id="sort"  placeholder="Enter Page Sorting Number">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="Type">Type</label>
                                <select formControlName="type"  class="form-control" placeholder="Type" (change)="changeCountry($event.target)">
                                    <option  *ngFor ="let count of pageList" [value]="count.name">{{count.name}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="SubType">SubType</label>
                                <select formControlName="sub_type"  class="form-control" placeholder="Sub Type" >
                                    <option *ngFor ="let sub of subType" [value]="sub">{{sub}} </option>
                              </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                            <label for="heading">Heading</label>
                            <input type="text" formControlName="heading" class="form-control" id="heading"  placeholder="Enter Page Heading">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                            <label for="description"> Page Description: </label>
                            <!-- <textarea type="text" rows="6" formControlName="description" class="form-control" id="description" placeholder="Enter Page Description"></textarea> -->
                            <angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="Banner"> Page Banner: </label>
                            <input type="file"  id="banner" #banner (change)="fileChangeEvent($event)"  class="form-control file" style=" height: calc(1.6em + 1rem + 2px);">
                            </div>
                        </div>   
                        <div class="col-md-6 mb-3 text-center">
                            <img *ngIf="pageDetail.banner_img" [src]="pageDetail.banner_img" class="img-fluid" height="250px" width="250px">
                        </div>
                        <div class="col-12 text-center">
                            <button value="submit" type="submit" [disabled]="EditPage.invalid"  class="btn btn-sub my-1">LETS CONTINUE</button>
                        </div>
                        </div>
                    </form>   
                </div>
                </div>
            <!-- </div>
        </div>
    </section> -->