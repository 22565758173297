import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  p:number = 1;
  allContacts: any;
  constructor(public router:Router,public mainservice:MainService,private notificationService:NotifyService) { }
  
  Contacts(){
    this.mainservice.TotalContact().subscribe((sub:any)=>{
      if(sub['success']){
        this.allContacts =sub['contact'];
      }else{
        this.notificationService.showError(sub['message'], "Failure !!");
      }
    });
  }
  ngOnInit(): void {
    this.Contacts();
  }

}
