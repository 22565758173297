import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';
import { WindowRefServiceService } from 'src/app/services/window-ref-service.service';


export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

@Component({
  selector: 'app-book-user',
  templateUrl: './book-user.component.html',
  styleUrls: ['./book-user.component.scss']
})
export class BookUserComponent implements OnInit {

  isindevidualShow: boolean = false;

  husbandorwifesShow: boolean = false;
  stateShow: boolean = false;
  cityShow: boolean = false;
  cityotherShow: boolean = false;


  isReady: boolean = false;
  isState: boolean = false;

  getinForm: FormGroup;
  viewModal: boolean = false;
  favoriteSeason: string = '';
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  navbarOpen = false;
  fileData: any = null;

  countryArr: any = [];
  stateArr: any = [];
  cityArr: any = [];

  constructor(private winRef: WindowRefServiceService, private elementRef: ElementRef, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private notify: NotifyService, public mainservice: MainService) {
    this.getinForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(3), Validators.maxLength(100)]),

      //      book_qty: new FormControl('', Validators.required),
      country_id: new FormControl('', Validators.required),
      state_id: new FormControl('', Validators.required),
      city_id: new FormControl('', Validators.required),
      city_other: new FormControl(''),
      mobile: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')
        , Validators.minLength(10), Validators.maxLength(10)]),
      address: new FormControl('', Validators.required),
      pincode: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.minLength(4), Validators.maxLength(6)]),
      tnc: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]), confirm_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')
    });


  }
  get gf(): any {
    return this.getinForm['controls'];
  }


  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }


  submit() {
    console.log('this.getinForm', this.getinForm);
    if (this.getinForm.valid) {
      this.isReady = true;
      let input = new FormData();
      // input.append("book_qty", this.gf.book_qty.value);
      input.append("name", this.gf.name.value);
      input.append("mobile", this.gf.mobile.value);
      input.append("address", this.gf.address.value);
      input.append("pincode", this.gf.pincode.value);
      input.append("country_id", this.gf.country_id.value);
      input.append("state_id", this.gf.state_id.value);
      input.append("city_id", this.gf.city_id.value);
      input.append("city_other", this.gf.city_id.value == 'other' ? this.gf.city_other.value : '');
      input.append("tnc", this.gf.tnc.value);
      this.fileData = input;

      this.mainservice.bookForm(this.fileData).subscribe(
        response => {
          this.isReady = false;
          if (response.status == 'error') {
            this.notify.showError(response.message, 'Error !!');
          } else {
            this.notify.showSuccess(response.message, 'Success !!');
            this.router.navigate(['/download']);

          }

        },
        error => {
          this.isReady = false;
          this.notify.showError(error.error['message'], 'Error !!');
        }
      );
    } else {
      this.isReady = false;
      this.notify.showError('Please select all information', 'Error !!');
    }
  }




  countryType(e: any) {
    if (e.target.value) {
      this.isState = true;
      let data = {
        'country_id': e.target.value,
      }
      this.mainservice.getState(data).subscribe(
        (res: any) => {
          this.stateArr = res['data'];
          this.isState = false;
        });
      this.stateShow = true;
    } else {
      this.isState = false;
      this.stateShow = false;
    }
  }

  stateType(e: any) {
    this.cityShow = false;
    this.isState = true;
    if (e.target.value) {
      let data = {
        'state_id': e.target.value,
      }
      this.mainservice.getCity(data).subscribe(
        (res: any) => {
          this.cityArr = res['data'];
          let otherarr = {
            id: 'other',
            name: "Other",
            pincode: null,
            state_id: '',
            updated_at: null
          }
          this.cityArr.push(otherarr);
          this.cityShow = true;
          this.isState = false;
        });

    } else {
      this.isState = false;
      this.cityShow = false;
    }
  }


  cityType(e: any) {
    if (e.target.value) {
      if (e.target.value == 'other') {
        this.getinForm.controls['city_other'].setValidators([Validators.required])
        // this.getinForm.addControl('city_other', new FormControl('', Validators.required));
        this.cityotherShow = true;
      } else {
        this.getinForm.controls['city_other'].clearValidators();
        this.cityotherShow = false;
      }
    } else {
      this.getinForm.controls['city_other'].clearValidators();
      this.cityotherShow = false;
    }
  }



  ngOnInit(): void {
    this.mainservice.getCountry().subscribe(
      (res: any) => {
        this.countryArr = res['data'];
      });
  }


}
