import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-getin-touch',
  templateUrl: './getin-touch.component.html',
  styleUrls: ['./getin-touch.component.scss']
})
export class GetinTouchComponent implements OnInit {
  getinForm: FormGroup;
  viewModal: boolean=false;
  demoForm: FormGroup;

  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private notify: NotifyService,public mainservice:MainService) {
    this.getinForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      email: new FormControl('',[Validators.required,Validators.email]),
      message:  new FormControl('', Validators.required),
    });
    this.demoForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      email: new FormControl('',[Validators.required,Validators.email]),
      service:  new FormControl('', Validators.required),
    });
  }
  get gf(): any {
    return this.getinForm['controls'];
  }
  get df(): any {
    return this.demoForm['controls'];
  }
  submit(){
    let data={
      'name':this.gf.name.value,
      'email':this.gf.email.value,
      'mobile':this.gf.mobile.value,
      'message':this.gf.message.value
    }
    console.log('message',data);
    this.mainservice.ContactForms(data).subscribe((res:any)=>{
      if(res['success']){
        this.getinForm.reset();
        this.notify.showSuccess(res['message'], "Success !!");
      }else{
        this.notify.showError(res['message'], "Failure !!");
      }
    });
  }
  Demosubmit(){
    let data={
      'name':this.df.name.value,
      'email':this.df.email.value,
      'mobile':this.df.mobile.value,
      'service':this.df.service.value
    }
    console.log('message',data);
    this.mainservice.DemoForms(data).subscribe((res:any)=>{
      if(res['success']){
        this.getinForm.reset();
        this.notify.showSuccess(res['message'], "Success !!");
      }else{
        this.notify.showError(res['message'], "Failure !!");
      }
    });
  }
  hide() {
    this.viewModal = false;
  }
  showModal(){
    this.viewModal = true;
  }
  ngOnInit(): void {
  }

}
