import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  AdminLogin: FormGroup;
  submitdata: any;
  message: any = '';
  navbarOpen: boolean = false;
  constructor(public router: Router, private formBuilder: FormBuilder, public mainservice: MainService) {
    this.AdminLogin = formBuilder.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', Validators.required),
    });
  }
  get af(): any {
    return this.AdminLogin['controls'];
  }

  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }

  OnAdmin() {
    this.submitdata = {
      'email': this.af.email.value,
      'password': this.af.password.value,
    },
      this.mainservice.AdminLog(this.submitdata).subscribe((res: any) => {
        if (res['success']) {
          sessionStorage.setItem('user', JSON.stringify(res['Admin']));
          // this.router.navigate(['/dashboard']);
          window.location.href = "/dashboard/blog"
        } else {
          this.message = res['message'];
        }
      });
  }
  ngOnInit(): void {
  }

}
