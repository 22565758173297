import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  navbarOpen = false;

  constructor() { }


  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }


  ngOnInit(): void {
  }


}
