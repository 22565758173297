<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn"> Terms and Conditions
                    </h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="main_div">
        <h1>
            Akhil Bhartiya Agrawal Mahasabha's Privacy Policy
        </h1>
        <p>
            Akhil Bhartiya Agrawal Mahasabha recognizes the importance of maintaining your privacy. We value your
            privacy
            and appreciate your trust in us. This Policy describes how we treat user information that we collect on
            Akhil
            Bhartiya Agrawal Mahasabha and other offline sources. This Privacy Policy applies to current and former
            visitors
            (“users” to our website and to our online customers). By visiting and/or using our website, you agree to
            this
            Privacy Policy.

        </p>
        <h5>
            Data Protection:
        </h5>
        <p>
            We don’t share any of your personal information with any third party or any individual. All the data is
            strictly
            protected under the judicial laws of data protection.
            Only in very extreme cases, we may only share information to respond to a court order or court subpoena.
            Before
            sharing any information, we will take your consent, before we go ahead with it.

        </p>
        <p>
            Information you provide and why it is necessary:
        </p>
        <p>
            We may collect your name, email, phone number, street, city, district, state, pin code, country. This
            information is used to create your profile on the website.

        </p>
        <p>
            We may ask you for your permission to upload photos, videos, and other file formats on the website. This is
            to
            enable you to set up your profile picture and upload posts, send messages.
            We use tracking tools like Google Analytics, Amplitude for collecting information about your usage of the
            website.
        </p>
        <p>
            We might send you information about special promotions. We might also tell you about new features or
            products.
            We might send you SMS or emails about your account to keep you informed and updated.

        </p>
        <p>
            if you wish to use any feature that involves payments to Akhil Bhartiya Agrawal Mahasabha, you can choose to
            provide us your Bank Account details through a secured payment gateway that will allow secure facilitate the
            payouts to our account by you.

        </p>
        <p>
            By submitting our webform, you agree to receive promotional calls on the number shared, and such calls and
            SMS
            would be coming from a third-party platform.

        </p>
        <h5>
            Jurisdiction :
        </h5>
        <p>
            If you choose to visit the website, your visit and any dispute over privacy are subject to this Policy and
            the
            website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be
            governed
            by the laws of India.

        </p>
        <h5>
            Contact :
        </h5>
        <p>
            If you have any questions about this Policy or other privacy concerns, you can write us at
            policy@abamahasabha.com
            Updated Date:
            This Privacy Policy is updated on January 14, 2022. From time to time we may change our privacy practices.
            We
            will notify you of any material changes to this policy as required by law. We will also post an updated copy
            on
            our website. Please check our site periodically for updates.


        </p>

    </div>
</div>