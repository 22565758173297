<!-- Navbar & Hero Start -->
<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn">उद्देश</h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Navbar & Hero End -->


<div class="container-xxl">
    <div class="container px-lg-1">
        <div class="row g-5">
            <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <div class="section-title position-relative mb-4 pb-2">
                    <!-- <h2 class="mt-2">महाराजा श्री अग्रसेन जी का जीवन परिचय
                    </h2> -->
                </div>
                <p class="mb-4">
                    अखिल भारतीय अग्रवाल महासभा की स्थापना देश विदेश में फैले हुए समस्त अग्र वालों को एकजुट करने के लिए
                    की गई है इसके
                    साथ साथ समाज में फैली हुई कुरीतियों को दूर करने के लिए एवं व्यवसाय/ शादी विवाह मैंआ रही समस्याओं के
                    समाधान के लिए यह प्लेटफार्म तैयार किया गया है जिससे समस्त अग्रबंधु एक दूसरे का सहयोग कर इन समस्याओं
                    को दूर कर अग्रवालों को सबसे आगे की पंक्ति में रखने के लिए वचनबद्ध होंगे
                    अग्रवाल महासभा के तहत राष्ट्रीय स्तर, प्रदेश स्तर, जिला स्तर एवं तहसील स्तर तक कार्यकारिणी गठित की
                    गई हैं जहां पर अभी तक कार्यकारिणी गठित नहीं हुई है वहां पर कार्यकारिणी गठित की जा रही है ताकि देश के
                    कोने कोने में अग्र बंधुओं की एक दूसरे तक पहुंच हो एवं किसी भी परेशानी में एक आवाज पर समस्त अग्रबंधु
                    एक दूसरे के सहयोग के लिए उठ खड़े हो


                </p>
            </div>
        </div>

        <!-- <div class="col-lg-6">
            <div class="row g-3">
                <div class="col-sm-6">
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गर्ग &nbsp;(Garg or Gargeya)
                    </h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गोयल (Goyal, Goel or Goenka)
                    </h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>जिंदल (Jindal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बिंदल (Bindal or Vindal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कंदल &nbsp;(Kandal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बंसल (Bansal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>जिंजल (Jinjal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>बिंछल (Binchhal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कांसल (Kansal)</h6>
                </div>
                <div class="col-sm-6">
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>भेदल &nbsp;(Bhedal / Bhandal)
                    </h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>मंगल (Mangal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>मित्तल (Mittal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>सिंगल (Singal, Singhal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>एरण (Airan/Aeron)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>नांगल (Nangal)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>कुंच्छल (Kuchhal / Kuchchal)
                    </h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>धारण (Dharan or Deran)</h6>
                    <h6 class="mb-2"><i class="fa fa-check text-primary me-2"></i>गोइन (Goin, Goyan, Gangal)
                    </h6>
                </div>
            </div>
        </div> -->

    </div>
</div>