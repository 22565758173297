import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  items: any;//GalleryItem[];
  navbarOpen = false;

  imageData: any = [];
  _album: any = [];

  constructor(public _lightbox: Lightbox, private _lightboxConfig: LightboxConfig) {
    this.imageData = this.data;
    _lightboxConfig.fadeDuration = 1;
    _lightboxConfig.disableScrolling = true;
    _lightboxConfig.fitImageInViewPort = true;
    _lightboxConfig.showImageNumberLabel = true;
    _lightboxConfig.alwaysShowNavOnTouchDevices = true;
    _lightboxConfig.centerVertically = true;
    _lightboxConfig.showZoom = true;
    _lightboxConfig.showRotate = true;
  }

  open(index: number) {
    // open lightbox
    this.data.forEach((element: any) => {
      this._album.push({ 'src': element.srcUrl });
    });
    this._lightbox.open(this._album, index, { wrapAround: true, showImageNumberLabel: true });
  }
  close(): void {
    // close lightbox programmatically
    this._album = [];
    this._lightbox.close();
  }

  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }


  ngOnInit() {

    /** Basic Gallery Example */

    // Creat gallery items
    // this.items = this.imageData.map((item: any) => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    /** Lightbox Example */

    // Get a lightbox gallery ref
    // const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    // lightboxRef.setConfig({
    //   imageSize: ImageSize.Cover,
    //   thumbPosition: ThumbnailsPosition.Top
    // });

    // Load items into the lightbox gallery ref
    // lightboxRef.load(this.items);
  }

  data: any = [
    {
      srcUrl: 'assets/images/gallery/1.jpeg',
      previewUrl: 'assets/images/gallery/1.jpeg'
    },
    {
      srcUrl: 'assets/images/gallery/2.jpeg',
      previewUrl: 'assets/images/gallery/2.jpeg'
    },
    {
      srcUrl: 'assets/images/gallery/3.jpeg',
      previewUrl: 'assets/images/gallery/3.jpeg'
    },
    {
      srcUrl: 'assets/images/gallery/4.jpeg',
      previewUrl: 'assets/images/gallery/4.jpeg'
    }
  ];
}
