import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-download-certificate',
  templateUrl: './download-certificate.component.html',
  styleUrls: ['./download-certificate.component.scss']
})
export class DownloadCertificateComponent implements OnInit {

  getinForm: FormGroup;
  navbarOpen = false;
  isReady: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private notify: NotifyService, public mainservice: MainService) {
    this.getinForm = this.formBuilder.group({
      dob: new FormControl('', Validators.required),
      mobile: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')
        , Validators.minLength(7), Validators.maxLength(15)]),
    });
  }

  ngOnInit(): void {
  }


  get gf(): any {
    return this.getinForm['controls'];
  }


  openNav() {
    this.navbarOpen = !this.navbarOpen;
  }

  submit() {
    this.isReady = true;
    if (this.getinForm.valid) {
      let input = new FormData();
      input.append("dob", this.gf.dob.value);
      input.append("mobile", this.gf.mobile.value);
      this.mainservice.createPDF(input).subscribe(
        response => {
          this.isReady = false;
          if (response.StatusCode == 200) {

            window.location.href = response.fileurl;
          }
          this.notify.showSuccess(response.message, 'Success !!');
        },
        error => {
          this.isReady = false;
          this.notify.showError(error.error['message'], 'Error !!');
        }
      );
    } else {
      this.isReady = false;
      this.notify.showError('Please select all information', 'Error !!');
    }
  }



}
