<!-- <section>
    <div class="row no-gutters">
        <div class="col-2 p-0 web" style="background: #0e2e7b;">
            <app-sidenav></app-sidenav>
        </div>
        <div class="col-md-10 col-sm-12 px-3"> -->
            <div class="row align-items-center no-gutters px-3 py-1">
            <div class="col-6">
                <h2 class="mb-0">Add Blog</h2>
            </div>
            <div class="col-6 text-right">
                <button class="btn-add btn" (click)="back()">Back  
                    <span class="material-icons">
                        undo
                    </span>
                </button>
            </div>
            </div>
            <div class="row justify-content-center my-3">
            <div class="col-md-12 col-sm-12 px-5">
                <form name="AddBlog" [formGroup]="AddBlog" (ngSubmit)="submit()" autocomplete="off">
                    <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="name"> Blog Title: </label>
                        <input type="text" formControlName="title" class="form-control" id="title"  placeholder="Enter Blog Title">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="heading"> Blog Heading: </label>
                        <input type="text" formControlName="heading" class="form-control" id="heading"  placeholder="Enter Blog Heading">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="subheading"> Blog SubHeading: </label>
                        <input type="text" formControlName="sub_heading" class="form-control" id="subheading"  placeholder="Enter Blog SubHeading">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="url">URL</label>
                        <input type="text" formControlName="seo_url" class="form-control" id="seo_url"  placeholder="Enter Blog Url">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="Meta Title"> Meta Title: </label>
                        <input type="text" formControlName="meta_title" class="form-control" id="meta_title"  placeholder="Meta Title">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="subheaMeta Keywordding"> Meta Keyword: </label>
                        <input type="text" formControlName="meta_keyword" class="form-control" id="meta_keyword"  placeholder="Meta Keyword">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="subheaMeta "> Meta Description: </label>
                        <input type="text" formControlName="meta_desc" class="form-control" id="meta_desc"  placeholder="Meta Description">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="Sort">Sort No</label>
                        <input type="text" formControlName="sort" class="form-control" id="sort"  placeholder="Enter Blog Sorting Number">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                        <label for="subdescription"> Blog SubDescription: </label>
                        <textarea type="text" rows="6" formControlName="sub_description" class="form-control" id="sub_description" placeholder="Enter Blog SubDescription"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                        <label for="description"> Blog Description: </label>
                        <!-- <textarea type="text" rows="6" formControlName="description" class="form-control" id="description" placeholder="Enter Blog Description"></textarea> -->
                        <angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="form-group">
                        <label for="Banner"> Blog Banner: </label>
                        <input type="file"  id="banner" #banner (change)="fileChangeEvent($event)"  class="form-control file" style=" height: calc(1.6em + 1rem + 2px);">
                        </div>
                    </div>   
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="Thumbnail"> Blog Thumbnail: </label>
                        <input type="file"  id="thumbnail" #thumbnail (change)="thumbnailFileInput($event)"  class="form-control file" style=" height: calc(1.6em + 1rem + 2px);">
                        </div>
                    </div>  
                    <div class="col-12 text-center">
                        <button value="submit" type="submit" [disabled]="AddBlog.invalid"  class="btn btn-sub my-1">LETS CONTINUE</button>
                    </div>
                    </div>
                </form>   
            </div>
            </div>
        <!-- </div>
    </div>
</section> -->