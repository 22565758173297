import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  isLoggedIn():Boolean{
    if(sessionStorage.getItem('user') != null){
      return true;
    }
    else{
      return false;
    }
  }
}
