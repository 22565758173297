import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent implements OnInit {
  p:number = 1;
  allSubscribers: any;
  constructor(public router:Router,public mainservice:MainService,private notificationService:NotifyService) { }
  
  Subscribers(){
    this.mainservice.TotalSubscriber().subscribe((sub:any)=>{
      if(sub['success']){
        this.allSubscribers =sub['subscribers'];
      }else{
        this.notificationService.showError(sub['message'], "Failure !!");
      }
    });
  }
  ngOnInit(): void {
    this.Subscribers();
  }

}
