<div class="container-xxl position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" class="navbar-brand p-0">
            <h1 class="m-0 logo_css">
                अखिल भारतीय अग्रवाल महासभा</h1>
            <!-- <img src="img/logo.png" alt="Logo"> -->
        </a>
        <button class="navbar-toggler" type="button" (click)="openNav()">
            <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar-nav ms-auto py-0">
                <a routerLink="/" class="nav-item nav-link active">होम</a>
                <a routerLink="/about-us" class="nav-item nav-link">हमारे बारे में
                </a>
                <a routerLink="/gallery" class="nav-item nav-link">फोटो</a>
                <!-- <a href="/member" class="nav-item nav-link">कार्यकारिणी</a> -->
                <a routerLink="/register-form" class="nav-item nav-link">सदस्यता फार्म</a>
                <a routerLink="/metrominial-form" class="nav-item nav-link">वैवाहिक फार्म</a>
                <a routerLink="/download" class="nav-item nav-link">डाउनलोड प्रमाण पत्र</a>
            </div>
        </div>
    </nav>

    <div class="container-xxl py-1 bg-primary hero-header mb-5">
        <div class="container my-5 py-5 px-lg-5">
            <div class="row g-5 py-5">
                <div class="col-12 text-left">
                    <h1 class="text-white animated zoomIn">फोटो</h1>
                    <hr class="bg-white mx-auto mt-0" style="width: 90px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                            <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Navbar & Hero End -->
<section>
    <div class="container">
        <div class="row">

            <div *ngFor="let img of data; let i=index;" class="col-md-3 col-sm-6">
                <div class="card pointer">
                    <img [src]="img.srcUrl" class="img-responsive w-100 top-img h-img" alt="" (click)="open(i)"
                        style="height: 250px;" />
                </div>
            </div>
        </div>
        <!-- <div class="gallerize-example">
            <p class="container_gal" gallerize>
                <span *ngFor="let img of data; let i=index;">
                    <img [src]="img.srcUrl" (click)="open(i)" style="height: 150px;">
                </span>
            </p>
        </div> -->
    </div>
</section>