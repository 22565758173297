import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  constructor() { }
  logOut(){
    localStorage.removeItem('user');
    localStorage.clear();
    sessionStorage.clear();
    window.location.href='/'; 
  }
  ngOnInit(): void {
  }

}
